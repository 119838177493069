<template>
    <div>
      <v-select
          :items="target_columns"
          dense
          outlined
          label="Ubicación*"
          v-model="match.location"
          clearable
        >
        <template slot="selection" slot-scope="data">
            {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="{ item }">
            <div v-if="item.name">{{ item.name }}</div>
            <div v-else class="text-caption">(Vacío)</div>
        </template>
        </v-select>
        <v-select
          :items="target_columns"
          dense
          outlined
          label="Código*"
          v-model="match.code"
          clearable
        >
        <template slot="selection" slot-scope="data">
            {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="{ item }">
           <div v-if="item.name">{{ item.name }}</div>
           <div v-else class="text-caption">(Vacío)</div>
        </template>
        </v-select>
        <v-select
          :items="target_columns"
          dense
          outlined
          label="Patente*"
          v-model="match.patent"
          return-object
          clearable
        >
        <template slot="selection" slot-scope="data">
            {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="{ item }">
            <div v-if="item.name">{{ item.name }}</div>
            <div v-else class="text-caption">(Vacío)</div>
        </template>
        </v-select>
        <v-select
          :items="target_columns"
          dense
          outlined
          label="Kilometraje"
          v-model="match.mileage"
          return-object
          clearable
        >
        <template slot="selection" slot-scope="data">
            {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="{ item }">
            <div v-if="item.name">{{ item.name }}</div>
            <div v-else class="text-caption">(Vacío)</div>
        </template>
        </v-select>
        <v-select
          :items="target_columns"
          dense
          outlined
          label="Acoplado"
          v-model="match.coupled_name"
          return-object
          clearable
        >
        <template slot="selection" slot-scope="data">
            {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="{ item }">
            <div v-if="item.name">{{ item.name }}</div>
            <div v-else class="text-caption">(Vacío)</div>
        </template>
        </v-select>
        <v-select
          :items="target_columns"
          dense
          outlined
          label="Marca"
          v-model="match.brand_name"
          clearable
        >
        <template slot="selection" slot-scope="data">
            {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="{ item }">
            <div v-if="item.name">{{ item.name }}</div>
            <div v-else class="text-caption">(Vacío)</div>
        </template>
        </v-select>
        <v-select
          :items="target_columns"
          dense
          outlined
          label="Tipo"
          v-model="match.type_name"
          clearable
        >
        <template slot="selection" slot-scope="data">
            {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="{ item }">
            <div v-if="item.name">{{ item.name }}</div>
            <div v-else class="text-caption">(Vacío)</div>
        </template>
        </v-select>
        <v-btn  color="orange" elevation="0"
        :style="buttonStyle" block
        :disabled="!match.code || !match.location || !match.patent"
         @click="uploadFile()" :loading="useTruckStore.loader_upload">Guardar cambios</v-btn>
        <v-btn  class="mt-2" elevation="0" block
        :disabled="useTruckStore.loader_upload"
         @click="handleBack()">Volver</v-btn>
    </div>
</template>
<script>

import { useTruckStore } from '@/trucks/store/truck';

export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    useTruckStore: useTruckStore(),
    target_columns: [],
    match: {
      code: null,
      location: null,
      patent: null,
      coupled_name: null,
      brand_name: null,
      type_name: null,
      mileage: null,
    },
    mapheaders: {
      code: 'Código',
      location: 'Ubicación',
      patent: 'Patente',
      coupled_name: 'Acoplado',
      brand_name: 'Marca',
      type_name: 'Tipo',
      mileage: 'Kilometraje',
    },
  }),
  watch: {
    columns: {
      handler(val) {
        this.autoSelectColumns();
        this.target_columns = val;
      },
      deep: true,
    },
  },
  computed: {
    buttonStyle() {
      return {
        color: this.match.code && this.match.location && this.match.patent ? 'white' : '',

      };
    },
  },
  methods: {
    autoSelectColumns() {
      Object.keys(this.mapheaders).forEach((key) => {
        const header = this.mapheaders[key];
        const column = this.columns.find((col) => col.name === header);
        if (column) {
          this.match[key] = column;
        }
      });
    },
    handleBack() {
      this.$emit('handleBack');
    },
    uploadFile() {
      this.useTruckStore.loader_upload = true;
      this.$emit('uploadFile', this.match, this.columns);
    },
  },
};
</script>
<style>
.textColor {
  color: white;
};
</style>
