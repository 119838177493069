<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div>
    <v-list-item>
      <v-icon class="mr-3"
      :disabled="useTruckStore.uploaded"
       @click="
       store.toogleGeneralDrawer(false, '');
       useTruckStore.complete_upload = false;">mdi-close</v-icon>
      Importar equipos
    </v-list-item>
    <v-divider></v-divider>
    <div class="container">
      <div v-if="useTruckStore.complete_upload" class="file-upload">
        <!-- <v-alert
          dense
          text
          :type="counter_imported.new_supplies === 0 ?'info':'success'"
          :color="counter_imported.new_supplies === 0 ?'grey':'green'"
        >
        <strong>{{ counter_imported.new_supplies }}</strong> Insumos importados
        </v-alert>
        <v-alert
          dense
          text
          type="warning"
        >
        <strong>{{ counter_imported.updated_supplies }}</strong> Insumos actualizados
        </v-alert>
        <v-alert
          dense
          text
          :type="counter_imported.new_categories === 0 ?'info':'success'"
          :color="counter_imported.new_categories === 0 ?'grey':'green'"
        >
        <strong>{{ counter_imported.new_categories }}</strong> Categorias creadas
        </v-alert>
        <v-alert
          dense
          text
          :type="counter_imported.new_units === 0 ?'info':'success'"
          :color="counter_imported.new_units === 0 ?'grey':'green'"
        >
        <strong>{{ counter_imported.new_units }}</strong> Unidades de medida creadas
        </v-alert> -->
        <v-alert
          dense
          text
          type="info"
          color="grey"
        >
        <div class="hash">

          <strong>Código de importación único </strong>
          <div class="text-caption">
            Utilza este código para eliminar los insumos importados.
          </div>
          <div class="mt-4">{{ temp_hash }}</div>
        </div>
        </v-alert>
  </div>
  <v-alert
      text
      prominent
      type="warning"
      icon="mdi-cloud-alert"
      v-if="errors.length > 0"
    >
      <div v-for="item in errors" :key="item.id">
        {{ item[0] }}
      </div>
    </v-alert>
  <div
      v-if="!view_preview"
      class="drop-area"
      @dragover.prevent
      @dragenter.prevent
      @drop="handleDrop"
      >
      <div class="d-flex flex-column justify-center align-center">
        <div>
          <v-icon size="50" color="grey">mdi-file-upload-outline</v-icon>
        </div>
        <div class="mt-3">
          <p>Arrastra un archivo aquí o haz
            <span class="text-caption" tabindex="0" @click="openFilePicker"
            @keydown.enter="openFilePicker">clic aquí</span>
              para seleccionarlo.
          </p>
          <input type="file" ref="filePicker" style="display: none" @change="handleFileChange" />
        </div>
      </div>
    </div>
    <div class="details"    v-if="!view_preview">
      <div>Formatos soportados : XLSX </div>

    </div>
    <v-alert
      class="mt-5"
      dense
      text
      color="grey"
      v-if="!view_preview"
    >
      <div class="container-template">
        <div>
          <div class="mb-3">
            <v-icon color="green">mdi-file-excel</v-icon>
             <strong :style="{ color: textColor() }">Archivo ejemplo</strong>
            </div>
          <div class="text-info-download">Puedes descargar el ejemplo adjunto y
            usarlo como punto de partida para tu propio archivo.</div>
          </div>
          <div><v-btn
            @click="downloadExcel('Archivo_importacion_equipos.xlsx')"
          elevation="0" small>Descargar</v-btn></div>
        </div>
    </v-alert>
    <v-card v-if="file && view_preview && !uploaded && !view_match_columns" elevation="1">
        <div class="preview">
          <v-icon color="green" size="35" class="mr-4">mdi-file-excel</v-icon>
          <div class="mb-3 d-flex flex-column">
            <strong>{{ file.name }}</strong>
            <div class="text-info-download">Tamaño: {{ file.size }} bytes</div>
          </div>
        </div>
        <v-container class="mt-3 mb-3">
          <v-select
              :items="sheets"
              label="Seleccione hoja"
              dense
              class="m-3"
              v-model="selectedSheet"
              hide-details
              item-text="name"
            ></v-select>
            <div class="text-caption mt-2"> {{ counter_rows }} filas</div>
        </v-container>
    </v-card>
    <v-btn v-if="!view_match_columns && view_preview" color="orange" elevation="0" dark block
    @click="view_match_columns=true">Siguiente</v-btn>
    <v-btn v-if="!view_match_columns && view_preview"  elevation="0"  block class="mt-2"
    @click="handleBackSelectFile()">Restablecer</v-btn>
    <div v-if="view_match_columns && !useTruckStore.complete_upload">
      <MatchColumns :columns="headers" @uploadFile="uploadFile" @handleBack="handleBack"/>
    </div>
</div>
</div>
</template>

<script>
import axios from 'axios';
import readXlsxFile from 'read-excel-file';
import { useAppStore } from '@/store/app';
import MatchColumns from '@/trucks/components/MatchColumns.vue';
import { useTruckStore } from '../store/truck';

export default {
  components: { MatchColumns },
  data() {
    return {
      store: useAppStore(),
      useTruckStore: useTruckStore(),
      file: null,
      uploaded: false,
      view_preview: false,
      view_match_columns: false,
      sheets: [],
      selectedSheet: null,
      errors: [],
      counter_imported: {
        new_supplies: 0,
        updated_supplies: 0,
        new_categories: 0,
        new_units: 0,
      },
      headers: [],
      counter_columns: 0,
      counter_rows: 0,
      temp_hash: '',
    };
  },
  watch: {
    selectedSheet() {
      readXlsxFile(this.file, { sheet: this.selectedSheet }).then((rows) => {
        // eslint-disable-next-line prefer-destructuring
        this.headers = rows[0].map((row, key) => ({ name: row, key }));
        this.counter_rows = rows.length - 1;
      });
    },
    view_match_columns() {
      readXlsxFile(this.file, { sheet: this.selectedSheet }).then((rows) => {
        // eslint-disable-next-line prefer-destructuring
        this.headers = rows[0].map((row, key) => ({ name: row, key }));
      });
    },
  },
  methods: {
    textColor() {
      console.log(this.$vuetify.theme.dark);
      return this.$vuetify.theme.dark ? 'white' : 'dark';
    },
    buttonColor() {
      return this.$vuetify.theme.dark ? 'white' : 'primary';
    },
    handleBack() {
      this.view_match_columns = false;
      this.view_preview = true;
      this.useTruckStore.complete_upload = false;
    },
    handleBackSelectFile() {
      this.file = null;
      this.view_preview = false;
    },
    openFilePicker() {
      this.$refs.filePicker.click();
    },
    handleFileChange(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0];

      this.view_preview = true;
      readXlsxFile(this.file, { getSheets: true }).then((sh) => {
        this.sheets = sh;
        // eslint-disable-next-line prefer-destructuring
        this.selectedSheet = this.sheets[0].name;
      });

      readXlsxFile(this.file).then((rows) => {
        this.counter_rows = rows.length - 1;
      });
    },
    handleFile(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0];
    },
    handleDrop(event) {
      event.preventDefault();

      // eslint-disable-next-line prefer-destructuring
      this.file = event.dataTransfer.files[0];

      this.view_preview = true;

      readXlsxFile(this.file, { getSheets: true }).then((sh) => {
        this.sheets = sh.map((row) => row.name);
        // eslint-disable-next-line prefer-destructuring
        this.selectedSheet = this.sheets[0];
      });

      readXlsxFile(this.file).then((rows) => {
        this.counter_rows = rows.length - 1;
      });
    },
    async uploadFile(matched, columns) {
      this.useTruckStore.uploaded = true;
      try {
        const formData = new FormData();
        formData.append('file', this.file);
        formData.append('sheet', this.selectedSheet);
        formData.append('columns', JSON.stringify(columns));

        Object.keys(matched).forEach((key) => {
          formData.append(`matched[${key}]`, matched[key] !== null ? matched[key].name : '');
        });

        const response = await axios.post('/v1/trucks-import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.errors = [];
        this.counter_imported.new_supplies = response.data.new_count;
        this.counter_imported.updated_supplies = response.data.updated_count;
        this.counter_imported.new_categories = response.data.new_category_count;
        this.counter_imported.new_units = response.data.new_unit_count;
        this.temp_hash = response.data.unique_key;
        this.useTruckStore.handleFetchTruck();
        this.useTruckStore.channel = response.data.channel;
        localStorage.setItem('temp_channel', response.data.channel);
      } catch (error) {
        if (error.response.status === 422) {
          this.useTruckStore.uploaded = false;
          this.useTruckStore.loader_upload = false;
          this.errors = error.response.data.errors;
        } else if (error.response.status === 500) {
          this.store.openSnackbar('⛔️ Error al subir el archivo', 'error');
          console.error('Error uploading file:', error);
          this.useTruckStore.uploaded = false;
          this.useTruckStore.loader_upload = false;
          this.errors = error.response.data.errors;
        }

        // Aquí puedes manejar el error como desees
      }
    },
    async downloadExcel(filename) {
      try {
        const response = await axios.get('v1/download-excel', {
          params: { file: filename },
          responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        link.remove();
      } catch (error) {
        this.store.openSnackbar('⛔️ Error al descargar el archivo', 'error');
      }
    },
  },
};
</script>

  <style scoped>
  .file-upload {
    height: 50%;
  }

  .drop-area {
    border: 2px dashed #ccc;
    padding: 20px;
    cursor: pointer;
    border-radius: 1em;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .drop-area p {
    margin: 0;
    color: grey
  }
  .details{
    margin-top: 6px;
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: grey;
  }

  .preview {
    margin-top: 20px;
    padding: 10px;
    display: flex;
  }

  .text-info-download {
    font-size: 13px;
    color: grey;
  }
  .container-template{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  .container-template strong
  {
    font-size: 14px;
    color: black;
  }

  .hash {
    word-wrap: break-word;
    width: 400px;
  }
  </style>
