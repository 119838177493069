<template>
  <div>
    <v-list-item>
      <v-icon class="mr-3" @click="store.toogleGeneralDrawer(false,'')">mdi-arrow-left</v-icon>
      Volver
    </v-list-item>
    <v-divider></v-divider>

    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <v-autocomplete
              color="orange"
              small-chips
              outlined
              chips
              v-model="alertsStore.filters.location"
              label="Ubicación"
              :item-text="(item) => item.id + ' - ' + item.name"
              :item-value="(item) => item.id"
              :items="store.locations"
              :error-messages="alertsStore.errors.selectedTrucks
                && alertsStore.errors.selectedTrucks[0]"
              clearable
              hide-details=""
              dense
              return-object
              clereable
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="12">
            <v-autocomplete
              color="orange"
              small-chips
              outlined
              chips
              multiple
              v-model="alertsStore.filters.trucks"
              label="Equipos"
              :item-text="(item) => item.code + ' - ' + item.patent"
              :item-value="(item) => item.id"
              :items="alertsStore.trucks"
              :error-messages="alertsStore.errors.selectedTrucks
                && alertsStore.errors.selectedTrucks[0]"
              clearable
              hide-details=""
              dense
              return-object
              clereable
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="12">
            <v-autocomplete
            color="orange"
            dense
            outlined
            v-model="alertsStore.filters.categories"
            label="Categoría"
            multiple
            :item-text="(item) => item.name"
            :item-value="(item) => item.id"
            :items="alertsStore.categories"
            :error-messages="alertsStore.errors.category_maintenance_alert_id
              && alertsStore.errors.category_maintenance_alert_id[0]"
            hide-details
            return-object
            clereable
            chips
            small-chips
          ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="12">
            <v-select
              v-model="alertsStore.filters.status"
              :items="statuses"
              label="Estado"
              outlined
              dense
              multiple
              return-object
              hide-details
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn small @click="applyFilters" elevation="0" block color="orange"
            dark>Aplicar Filtros</v-btn>
            <v-btn class="mt-3" small @click="alertsStore.clearFilters" elevation="0" block
            >Limpiar Filtros</v-btn>
          </v-col>

        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { useAppStore } from '@/store/app';
import { useMaintenanceAlertsStore } from '@/maintenance-alerts/store/maintenance-alerts-store';

export default {
  data: () => ({
    store: useAppStore(),
    alertsStore: useMaintenanceAlertsStore(),
    statuses: [
      { text: 'Creada', value: 'created' },
      { text: 'Activa', value: 'current' },
      { text: 'Ejecutada', value: 'executed' },
    ],
    categories: [],
    trucks: [],
  }),
  watch: {
    'store.generalDrawer': {
      handler(val) {
        if (val) {
          this.trucks = this.alertsStore.trucks;
          this.categories = this.alertsStore.categories;
        }
      },
      deep: true,
    },
  },
  created() {
    this.store.fetchLocations();
    this.alertsStore.getTrucks();
    this.alertsStore.getCategories();
  },
  methods: {
    async applyFilters() {
      const targetCategories = this.alertsStore.filters.categories.map((category) => category.id);
      const targetTrucks = this.alertsStore.filters.trucks.map((truck) => truck.id);
      const targetStatuses = this.alertsStore.filters.status.map((status) => status.value);
      const targetLocations = this.alertsStore.filters.location
      && this.alertsStore.filters.location.id;

      this.alertsStore.filterSelected = [...this.alertsStore.filters.trucks.map(
        (truck) => ({ value: truck.id, text: truck.patent, type: 'truck' }),
      )];
      this.alertsStore.filterSelectedCategories = [...this.alertsStore.filters.categories.map(
        (category) => ({ value: category.id, text: category.name, type: 'category' }),
      )];
      this.alertsStore.filterSelectedStatus = [...this.alertsStore.filters.status.map(
        (status) => ({ value: status.value, text: status.text, type: 'status' }),
      )];

      this.alertsStore.combinedFilterSelected = [
        ...this.alertsStore.filterSelected,
        ...this.alertsStore.filterSelectedCategories,
        ...this.alertsStore.filterSelectedStatus];

      localStorage.setItem('tableOptionsListAlertsCombinedFilterSelected', JSON.stringify(
        this.alertsStore.combinedFilterSelected,
      ));

      const filters = {
        trucks: targetTrucks,
        categories: targetCategories,
        status: targetStatuses,
        location: targetLocations,
      };
      try {
        await this.alertsStore.getAlerts(filters);
      } catch (error) {
        this.store.snackbar = {
          visible: true,
          text: 'Error al aplicar los filtros',
        };
      }
    },
    updateSelection(newItems, currentSelection, mapItemToObject) {
      const mappedItems = newItems.map(mapItemToObject);

      const isUniqueItem = (item, selectedItems) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        !selectedItems.some((selected) => selected.value === item.value);

      const uniqueItems = mappedItems.filter((item) => isUniqueItem(item, currentSelection));

      return [
        ...currentSelection.filter((selected) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          mappedItems.some((item) => item.value === selected.value)),
        ...uniqueItems,
      ];
    },
  },
};
</script>
