/* eslint-disable */
import {
  defineStore,
} from 'pinia';
import apiTruck from '@/trucks/services/truckSevice';

export const useTruckStore = defineStore('truck', {
  state: () => ({
    channel: '',
    importFailed: false,
    loader_upload : false,
    complete_upload : false,
    uploaded : false,
    trucks : [],
    locations: [],
    categories: [],
    centerCosts: [],
    errors_form_truck: {
    },
    measurement_units: [],
    counterFilter : 0,
    formTruck: {
      id : '',
      workshop_id: '',
      code: '',
      patent: '',
      coupled_id: '',
      type_of_truck_id: '',
      brand_id: '',
      cost_center_id: '',
    },
    defaultFormTruck: {
      id : '',
      workshop_id: '',
      code: '',
      patent: '',
      coupled_id: '',
      type_of_truck_id: '',
      brand_id: '',
      cost_center_id: '',
    },
    default_filter_trucks: {
      category_id: '',
      location: '',
      selectedCategories : []
    },
    filter_trucks: {
      search : '',
      search_loading : false,
      category_id: '',
      location: '',
      selectedCategories : []
    },
    totalTrucks: 0,
    coupled: {
      patent: null,
      workshop_id: null,
      company_id: null,
    },
    brand: {
      name: null,
      workshop_id: null,
    },
    typeOfTruck: {
      name: null,
      workshop_id: null,
    },
    centerCost: {
      name: null,
      description: null,
      workshop_id: null,
      company_id: null,
    },
    brands: [],
    types_of_truck: [],
    uploaded: false,

  }),
  actions: {
    async storeTruck(truck) {
      const response = await apiTruck.createTruck(truck);
      this.formTruck = response.data;

      return response;
    },
    async updateTruck(truck) {
      const response = await apiTruck.updateTruck(truck.id, truck);
      this.formTruck = response.data;

      return response;
    },
    async fetchBrands() {
      try {
        const response = await apiTruck.getBrands();
        this.brands = response.data;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
      // handle the response data here
    },
    async fetchTruck(id) {
      const response = await apiTruck.getTruck(id);
      this.formTruck = response.data;
    },
    async fetchCenterCosts() {
      const response = await apiTruck.getCenterCosts();
      this.centerCosts = response.data;
    },
    async fetchTypesOfTruck() {
      try {
        const response = await apiTruck.getTypeOfTrucks();
        this.types_of_truck = response.data;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    async fetchMeasurementUnits(workshopId) {
      const response = await apiTruck.getMeasurementUnits(workshopId);
      this.measurement_units = response.data;
    },
    async handleFetchTruck(filter) {
      this.filter_trucks.search_loading = true;
      try {
        const response = await apiTruck.getTrucksList(filter);

        this.trucks = response.data.data.data;

        this.totalTrucks = response.data.data.total

      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
      this.filter_trucks.search_loading = false;
      // handle the response data here
    },
    handleDeleteTruck(id) {
      return apiTruck.deleteTruck(id);
    },
    resetFormTruck() {
      this.formTruck = this.defaultFormTruck;

    },
    storeCoupled(coupled){
      return apiTruck.createCoupled(coupled);
    },
    storeBrand(brand){
      return apiTruck.createBrand(brand);
    },
    storeCenterCost(centerCost){
      return apiTruck.createCenterCost(centerCost);
    },
    storeTypeOfTruck(typeOfTruck){
      return apiTruck.createTypeOfTruck(typeOfTruck);
    },
  },
});
