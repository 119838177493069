<template>
    <v-app class="app">
      <v-snackbar v-model="store.snackbar.visible">
        {{ store.snackbar.text }}
        <template v-slot:actions>
          <v-btn color="red" variant="text" @click="store.snackbar.visible = false"> Cerrar </v-btn>
        </template>
      </v-snackbar>
      <drawer-filter-right></drawer-filter-right>
      <v-sheet color="secondary">
        <router-view></router-view>
      </v-sheet>
      <!-- General container drawer  -->
      <container-drawer v-if="store.currentForm || store.currentFormDialog"
      :drawerWidth="store.currentFormDialog === 'form-upload-supplies'
      || store.currentFormDialog === 'form-upload-trucks'  ? 500 : 400">

        <template #container>
          <side-bar-create-alert v-if="store.currentFormDialog === 'form-alert'">
          </side-bar-create-alert>
          <attach-list-product v-if="store.currentFormDialog === 'list-suppliers'">
          </attach-list-product>
          <form-existence v-if="store.currentForm === 'update-existence'" />
          <form-upload-supplies v-if="store.currentFormDialog === 'form-upload-supplies'" />
          <form-upload-truck v-if="store.currentFormDialog === 'form-upload-trucks'">
          </form-upload-truck>
          <form-service-provider :showToogle="true" :showCreateCategory="false"
          v-if="store.currentFormDialog === 'form-service-provider'" />
          <form-provider-service-category
          v-if="store.currentFormDialog === 'form-provider-service-category'" />
          <form-coupled v-if="store.currentFormDialog === 'form-truck-coupled'"></form-coupled>
          <form-brand v-if="store.currentFormDialog === 'form-truck-brand'"></form-brand>
          <form-type v-if="store.currentFormDialog === 'form-truck-type'"></form-type>
          <form-filter-alert v-if="store.currentFormDialog === 'form-filter-alert'">
          </form-filter-alert>
          <form-cost-center v-if="store.currentFormDialog === 'form-center-cost'">
          </form-cost-center>
          <form-category-alert v-if="store.currentFormDialog === 'form-category-alert'">
          </form-category-alert>
        </template>
      </container-drawer>

      <!-- General modal dialog  -->
      <general-dialog>

        <template #container-form>
          <form-transaction v-if="store.currentFormDialog === 'form-warehouse-entry'" />
          <form-purchase-order v-if="store.currentFormDialog === 'form-purchase-order'" />
        </template>

        <template #content>
          <table-supplies-selected></table-supplies-selected>
        </template>

        <template #container>
          <container-drawer>
            <template #container>
              <form-select-supply-purchase-order></form-select-supply-purchase-order>
            </template>
          </container-drawer>
        </template>
      </general-dialog>
    </v-app>
</template>

<script>
import { useAppStore } from '@/store/app';
import { useInvertoryStore } from '@/inventory/store/inventory';
import FormFilterAlert from '@/maintenance-alerts/components/FormFilterAlerts.vue';
import GeneralDialog from '@/components/GeneralDialog.vue';
import InventoryService from '@/inventory/services/InventoryService';
import OtsService from './worker_order/services/OtsService';
import DrawerFilterRight from './components/DrawerFilterRight.vue';
import SideBarCreateAlert from './maintenance-alerts/components/SideBarCreateAlert.vue';
import ContainerDrawer from './components/ContainerDrawer.vue';
import AttachListProduct from './warehouse/components/AttachListProduct.vue';
import FormPurchaseOrder from './warehouse/components/FormPurchaseOrder.vue';
import FormSelectSupplyPurchaseOrder from './warehouse/components/FormSelectSupplyPurchaseOrder.vue';
import TableSuppliesSelected from './warehouse/components/TableSuppliesSelected.vue';
import { useWarehouseStore } from './warehouse/store/warehouse';
import FormTransaction from './warehouse/components/FormTransaction.vue';
import FormExistence from './warehouse/components/FormExistence.vue';
import SettingService from './SettingServices';
import FormUploadSupplies from './inventory/views/FormUploadSupplies.vue';
import FormServiceProvider from './provider/components/FormServiceProvider.vue';
import FormProviderServiceCategory from './provider/components/FormProviderServiceCategory.vue';
import FormCoupled from './trucks/components/FormCoupled.vue';
import FormBrand from './trucks/components/FormBrand.vue';
import FormType from './trucks/components/FormType.vue';
import FormUploadTruck from './trucks/components/FormUploadTruck.vue';
import FormCostCenter from './trucks/components/FormCostCenter.vue';
import FormCategoryAlert from './maintenance-alerts/components/FormCategoryAlert.vue';

export default {
  components: {
    DrawerFilterRight,
    ContainerDrawer,
    AttachListProduct,
    GeneralDialog,
    FormPurchaseOrder,
    TableSuppliesSelected,
    FormSelectSupplyPurchaseOrder,
    FormTransaction,
    FormExistence,
    FormUploadSupplies,
    FormServiceProvider,
    FormProviderServiceCategory,
    FormCoupled,
    FormBrand,
    FormType,
    FormUploadTruck,
    SideBarCreateAlert,
    FormFilterAlert,
    FormCostCenter,
    FormCategoryAlert,
  },
  data: () => ({
    store: useAppStore(),
    inventoryStore: useInvertoryStore(),
    useWarehouseStore: useWarehouseStore(),
    error: '',
    loaded: false,
  }),
  async mounted() {
    try {
      // Cargar datos en paralelo usando Promise.all
      await Promise.all([this.fetchSettings()]);
    } catch (error) {
      this.error = error;
    }
    this.loaded = true;
  },
  methods: {
    async fetchInitialData() {
      console.log('Loading data');
      try {
        const locationsPromise = OtsService.getLocations();
        const measurementUnitsPromise = InventoryService.getMeasurementUnits();
        const categoriesPromise = InventoryService.getCategories();

        const [locations, measurementUnits, categories] = await Promise.all([
          locationsPromise,
          measurementUnitsPromise,
          categoriesPromise,
        ]);

        this.inventoryStore.locations = locations.data;
        this.inventoryStore.measurement_units = measurementUnits.data;
        this.inventoryStore.categories = categories.data;
      } catch (error) {
        throw new Error('Error al cargar los datos iniciales.');
      }
    },
    async fetchSettings() {
      console.log('Loading settings');
      try {
        const response = await SettingService.getSettings();
        const settings = response;

        // Asigna las configuraciones al estado de la aplicación
        this.store.settings = settings;

        this.store.form_create_work.has_warehouse = settings[0].pivot.setting_value === 1;

        // Guarda las configuraciones en el localStorage
        localStorage.setItem('settings', JSON.stringify(settings));
      } catch (error) {
        throw new Error('Error al cargar las configuraciones.');
      }
    },
  },
};
</script>

<style>

</style>

<style >

.theme--dark.v-application {
    background: #242424 !important;
    color: #fff;
}

.v-dialog.v-dialog--active.v-dialog--persistent.v-dialog--fullscreen {
  overflow: hidden !important;
}

.app {
  overflow: hidden !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-material.css";

</style>
