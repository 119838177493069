/* eslint-disable */
// src/services/api.js
import axios from '@/plugins/axios';

export default {
  getCategories() {
    return axios.get('/v1/maintenance-alerts/categories');
  },

  getAlerts(params) {
    console.log(params);
    const targetParams = {
      ...params,
    };

    const targetFilter = {};

    for (const key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'page') {
          targetFilter.page = targetParams.page;
        }
        if (key == 'per_page') {
          targetFilter.per_page = targetParams.per_page;
        }
        if (key == 'search') {
          targetFilter['filter[Search]'] = targetParams.search && targetParams.search;
        }
        if (key == 'trucks') {
          targetFilter['filter[Trucks]'] = targetParams.trucks && targetParams.trucks.map((truck) => truck).join(',');
        }
        if (key == 'categories') {
          targetFilter['filter[Categories]'] = targetParams.categories && targetParams.categories.map((category) => category).join(',');
        }
        if (key == 'status') {
          targetFilter['filter[Status]'] = targetParams.status && targetParams.status.map((status) => status).join(',');
        }
        if (key == 'location') {
          targetFilter['filter[WorkshopId]'] = targetParams.location && targetParams.location;
        }
      }
    }

    return axios.get('/v1/maintenance-alerts', {
      params: targetFilter,
    });
  },

  getTrucks() {
    return axios.get('/v1/trucks');
  },
  storeAlert(formAlert) {

    let targetForrmAlert = {
      ...formAlert,
      category_maintenance_alert_id : formAlert.category_maintenance_alert_id.map(
        (category) => category.id
      )
    };
    return axios.post('/v1/maintenance-alerts', targetForrmAlert);
  },
  deleteAlert(id) {
    return axios.delete(`/v1/maintenance-alerts/${id}`);
  },
  updateAlert(formAlert) {
    console.log(formAlert);

    let targetForrmAlert = {
      ...formAlert,
      category_maintenance_alert_id : formAlert.category_maintenance_alert_id.map(
        (category) => category.id
      )
    };

    return axios.put(`/v1/maintenance-alerts/${formAlert.id}`, targetForrmAlert);
  },
  deleteManyAlerts(ids) {
    return axios.post('/v1/maintenance-alerts/delete-many', { ids });
  },
  getEmails() {
    return axios.get('/v1/maintenance-alerts/emails-users');
  },
  updateStatusToEjecuted(ids) {
    console.log(ids);
    return axios.post('/v1/maintenance-alerts/status', {
      ids: ids,
      status: 'executed'
    });
  },
  storeCategoryAlert(formCategory) {
    return axios.post('/v1/maintenance-alerts/categories', formCategory);
  }
};
