<template>
  <div class="mx-3 my-3">
    <!-- Insumo (Autocomplete) -->
    <div style="width: 100%" class="d-flex justify-space-between">
      <div><v-icon class="mr-3" @click="back()">mdi-arrow-left</v-icon> Agregar insumo</div>
      <div>
        <v-btn
          v-if="!useOrderPurchase.isEdited"
          :disabled="!isValidForm()"
          @click="addSupply()"
          class="ml-3"
          small
          color="orange"
          dark
          elevation="0"
          >Guardar</v-btn
        >
        <v-btn
          v-if="useOrderPurchase.isEdited"
          @click="handleUpdateSupply()"
          class="ml-3"
          small
          color="orange"
          dark
          elevation="0"
          >Actualizar</v-btn
        >
      </div>
    </div>
    <br />
    <!-- Unidad Pedida -->
    <v-text-field
      v-model="useOrderPurchase.form_request_supply.unitRequest"
      label="Cantidad"
      outlined
      @change="changeUnitRequest"
      dense
    ></v-text-field>

    <!-- Costo Unitario -->
    <v-text-field
      v-model="useOrderPurchase.form_request_supply.unitCost"
      label="Costo Unitario"
      outlined
      dense
    >
      <template v-slot:prepend-inner>
        <v-icon size="20" color="grey">mdi-currency-usd</v-icon>
      </template>
    </v-text-field>

    <!-- Impuesto (Select) -->
    <v-select
      v-model="useOrderPurchase.form_request_supply.tax"
      :items="taxItems"
      label="Impuesto"
      item-text="item"
      item-value="value"
      outlined
      dense
      @change="changeTax"
    >
      <template v-slot:prepend-inner>
        <v-icon size="20" color="grey">mdi-percent-outline</v-icon>
      </template></v-select
    >
    <v-text-field
      v-model="useOrderPurchase.form_request_supply.neto"
      disabled
      label="Costo neto"
      outlined
      dense
    >
      <template v-slot:prepend-inner>
        <v-icon size="20" color="grey">mdi-currency-usd</v-icon>
      </template></v-text-field
    >
    <!-- Costo Total -->
    <v-text-field
      v-model="useOrderPurchase.form_request_supply.total"
      disabled
      label="Costo total"
      outlined
      dense
    >
      <template v-slot:prepend-inner>
        <v-icon size="20" color="grey">mdi-currency-usd</v-icon>
      </template></v-text-field
    >
  </div>
</template>

<script>
import formatCLP from '@/helpers/money';
import { useAppStore } from '@/store/app';
import { useInvertoryStore } from '@/inventory/store/inventory';
import { useWarehouseStore } from '../store/warehouse';
import { useOrderPurchase } from '../store/orderPurchase';

export default {
  data() {
    return {
      store: useAppStore(),
      useWarehouseStore: useWarehouseStore(),
      useOrderPurchase: useOrderPurchase(),
      useInvertoryStore: useInvertoryStore(),
      taxItems: [
        { item: '19%', value: 1.19, otherValue: 0.19 },
        { item: '21%', value: 1.21, otherValue: 0.21 },
        { item: 'Exento', value: 1.0, otherValue: '0' },
      ],
    };
  },
  created() {
    this.useOrderPurchase.fetchSuppliesAvailable(this.$route.params.id);
    this.useInvertoryStore.fetchMeasurementUnits();
  },
  methods: {
    formatCLP,
    back() {
      this.store.toogleGeneralDrawer(false, this.store.currentFormDialog);
      this.useOrderPurchase.resetFormRequestSupply();
      this.useOrderPurchase.isEdited = false;
    },
    isValidForm() {
      return (
        this.useOrderPurchase.form_request_supply.supply != null
        && this.useOrderPurchase.form_request_supply.unitRequest != null
        && this.useOrderPurchase.form_request_supply.unitCost != null
        && this.useOrderPurchase.form_request_supply.total != null
        && this.useOrderPurchase.form_request_supply.tax != null
      );
    },
  },
};
</script>
