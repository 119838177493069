// src/services/api.js
import axios from '@/plugins/axios';

export default {
  getWarehousesByLocation(workshop) {
    return axios.get('/v1/warehouses', {
      params: {
        'filter[WorkshopId]': workshop,
      },
    });
  },
  getWarehouses() {
    return axios.get('/v1/warehouses');
  },
  getWarehouse(warehouseId) {
    return axios.get(`/v1/warehouses/${warehouseId}`);
  },
  createWarehouse(warehouse) {
    return axios.post('/v1/warehouses', warehouse);
  },

  updateWarehouse(warehouseId, updatedWarehouse) {
    return axios.put(`/v1/warehouses/${warehouseId}`, updatedWarehouse);
  },

  deleteWarehouse(warehouseId) {
    return axios.delete(`/v1/warehouses/${warehouseId}`);
  },

  getStockSupplies() {
    return axios.get('/v1/warehouses');
  },

  // Stock by warehouse
  attachSupplyToWarehouse(warehouseId, suppliesList, selectedAllParam) {
    return axios.put(`/v1/stock/${warehouseId}`, {
      warehouse: warehouseId,
      supplies: suppliesList,
      selectedAll: selectedAllParam,
    });
  },

  getStock(warehouseId, targetParams) {
    return axios.get(`/v1/stock/${warehouseId}`, {
      params: {
        ...targetParams,
      },
    });
  },

  getSuppliesSelectable(warehouseId, params) {
    return axios.get('/v1/supplies-attached', {
      params: {
        warehouse: warehouseId,
        page: params.page,
        perPage: params.perPage,
        search: params.search,
      },
    });
  },
  getInStock(warehouseId, valParams) {
    return axios.get('/v1/supplies-in-stock', {
      params: {
        warehouse: warehouseId,
        ...valParams,
      },
    });
  },
  getProviders() {
    return axios.get('/v1/providers');
  },
  downloadSummaryEntry(params) {
    const targetParams = {
      ...params,
    };
    const targetFilter = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const key in targetParams) {
      // eslint-disable-next-line no-prototype-builtins
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key === 'date') {
          targetFilter['filter[BetweenDate]'] = `${targetParams.date[0]},${
            targetParams.date[1]}`;
        }
        if (key === 'warehouse') {
          targetFilter['filter[Warehouse]'] = targetParams.warehouse;
        }
      }
    }

    return axios.get('v1/download_entries', {
      responseType: 'blob',
      params: targetFilter,
    });
  },

};
