<template>
  <v-data-table
    :headers="tableHeaders"
    :items="useOrderPurchase.form_purchase_order.supplies"
    :footer-props="{
      itemsPerPageOptions: [5, 10, 25, 50],
      'items-per-page-text': 'Insumos por página',
    }"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="useOrderPurchase.form_purchase_order.showed"
          @click="handleAddSupply()"
          elevation="0"
          dark
          small
          class="ml-4"
          color="orange"
        >
          <v-icon size="25" class="mr-2">mdi-plus</v-icon>
          Agregar insumo
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
    </template>
    <template v-slot:[`item.description`]="{ item }">
      {{ item.description }}
    </template>
    <template v-slot:[`item.delivered`]="{ item }">
      {{ item.delivered }}
    </template>
    <template v-slot:[`item.unitCost`]="{ item }">
      {{ formatCLP(item.unitCost) }}
    </template>
    <template v-slot:[`item.taxObject`]="{ item }">
      {{ item.taxObject && item.taxObject.item }}
    </template>
    <template v-slot:[`item.total`]="{ item }">
      {{ formatCLP(item.total) }}
    </template>
    <template v-slot:[`item.neto`]="{ item }">
      {{ formatCLP(item.neto) }}
    </template>
    <template v-slot:[`item.tax`]="{ item }">
      {{ formatCLP(item.tax) }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        :disabled="useOrderPurchase.form_purchase_order.showed"
        icon
        small
        @click="editItem(item)"
      >
        <v-icon size="20">mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        :disabled="useOrderPurchase.form_purchase_order.showed"
        icon
        small
        @click="deleteItem(item)"
      >
        <v-icon size="20">mdi-delete</v-icon>
      </v-btn>
    </template>
    <template v-slot:footer>
      <div class="d-flex justify-end my-4 mx-4">
        <div class="d-flex">
          <div class="d-flex flex-column" style="width: 20em">
            <div class="font-weight-bold">Subtotal :</div>
            <div class="font-weight-bold">IVA :</div>
            <div class="font-weight-bold">Total :</div>
          </div>
          <div class="d-flex flex-column">
            <div class="font-weight-bold">
              {{
                useOrderPurchase.form_purchase_order.subtotal
                  ? formatCLP(useOrderPurchase.form_purchase_order.subtotal)
                  : '$' + 0
              }}
            </div>
            <div class="font-weight-bold">
              {{
                useOrderPurchase.form_purchase_order.tax
                  ? formatCLP(useOrderPurchase.form_purchase_order.tax)
                  : '$' + 0
              }}
            </div>
            <div class="font-weight-bold">
              {{
                useOrderPurchase.form_purchase_order.total
                  ? formatCLP(useOrderPurchase.form_purchase_order.total)
                  : '$' + 0
              }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import formatCLP from '@/helpers/money';
import { useAppStore } from '../../store/app';
import { useWarehouseStore } from '../store/warehouse';
import { useOrderPurchase } from '../store/orderPurchase';
import { useInvertoryStore } from '../../inventory/store/inventory';

export default {
  data() {
    return {
      useAppStore: useAppStore(),
      useWarehouseStore: useWarehouseStore(),
      useOrderPurchase: useOrderPurchase(),
      useInventoryStore: useInvertoryStore(),
      totalCost: 0,
      tableHeaders: [
        { text: '', value: 'actions' },
        { text: 'Descripción', value: 'supply.name' },
        { text: 'Cantidad entregada', value: 'unitRequest' },
        { text: 'Costo unitario', value: 'unitCost' },
        { text: 'Impuesto', value: 'taxObject' },
        { text: 'Valor impuesto', value: 'tax' },
        { text: 'Costo neto', value: 'neto' },
        { text: 'Costo total', value: 'total' },
      ],
    };
  },
  methods: {
    formatCLP,
    handleAddSupply() {
      this.useAppStore.generalDrawer = true;
      this.useWarehouseStore.resetFormRequestSupply();
      this.useOrderPurchase.fetchSuppliesAvailable(this.$route.params.id);
      this.useInventoryStore.fetchMeasurementUnits();
    },
    editItem(item) {
      this.useOrderPurchase.isEdited = true;
      this.useOrderPurchase.readSupply(item.supply.id);
      if (this.isPurchaseOrderRoute()) {
        this.useAppStore.toogleGeneralDrawer(true, 'form-purchase-order');
      } else {
        this.useAppStore.toogleGeneralDrawer(true, 'form-warehouse-entry');
      }
    },
    deleteItem(item) {
      this.useOrderPurchase.deleteSupply(item.supply.id);
      const { total, subtotal, tax } = this.useOrderPurchase.calculateTotals();
      this.useOrderPurchase.form_purchase_order.total = total;
      this.useOrderPurchase.form_purchase_order.subtotal = subtotal;
      this.useOrderPurchase.form_purchase_order.tax = tax;
    },
    isPurchaseOrderRoute() {
      return this.$route.path.includes('purchases-orders');
    },
  },
};
</script>
