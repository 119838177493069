/* eslint-disable */
// src/stores/warehouseStore.js
import apiWarehouse from '@/warehouse/services/WarehouseService';
import apiOrderPurchase from '@/warehouse/services/OrderPurchaseService';
import {
  defineStore
} from 'pinia';
import moment from 'moment';
export const useOrderPurchase = defineStore('useOrderPurchase', {
  state: () => ({
    isEdited: false,
    orderPurchases: [],
    priorityOptions: [{
      value: 'high',
      text: 'Alta'
    }, {
      value: 'medium',
      text: 'Media'
    }, {
      value: 'low',
      text: 'Baja'
    }],
    form: {
      code: "",
      name: "",
      description: "",
      namedress: "",
    },
    form_purchase_order: {
      id: null,
      showed: false,
      provider_id: '',
      status: '',
      document: '',
      countSupplies: '',
      createdAt: '',
      deliveryAt: '',
      reference: '',
      note: '',
      total: '',
      tax: '',
      subtotal: '',
      supplies: [],
      priority: '',
      warehouse_id: '',
    },
    last_form_request_supply: {},
    form_request_supply: {
      supply: null,
      unit: null,
      unitRequest: null,
      unitCost: null,
      total: null,
      tax: null,
      neto: null,
      taxObject: {
        value: 1.19,
        item: '19%'
      }
    },
    providers: [],
    supplies_available: [],
    errors: {},
    taxItems: [{
        item: '19%',
        value: 1.19,
        otherValue: 0.19
      },
      {
        item: '21%',
        value: 1.21,
        otherValue: 0.21
      },
      {
        item: 'Exento',
        value: 1.0,
        otherValue: '0'
      },
    ],
  }),

  actions: {
    async fetchOrderPurchase(warehouseId) {
      const response = await apiOrderPurchase.getOrderPurchases(warehouseId);
      this.orderPurchases = response.data;
    },
    async fetchProviders() {
      const response = await apiWarehouse.getProviders();
      this.providers = response.data;
    },
    async fetchSuppliesAvailable(orderPurchaseId) {
      const response = await apiWarehouse.getInStock(orderPurchaseId);
      this.supplies_available = response.data.data;
    },
    async deleteOrderPurchase(orderPurchaseId) {
      await apiOrderPurchase.deleteOrderPurchase(orderPurchaseId);
      this.orderPurchases = this.orderPurchases.filter(op => op.id !== orderPurchaseId);
    },

    calculateTotals() {
      const subtotal = this.form_purchase_order.supplies.reduce((acc, supply) => {
        const supplySubtotal = supply.unitCost * supply.unitRequest;
        const supplyTax = supplySubtotal * supply.taxObject.otherValue;
        supply.totalCost = supplySubtotal;
        supply.tax = supplyTax;
        return acc + supply.totalCost;
      }, 0);


      const tax = this.form_purchase_order.supplies.reduce((acc, supply) => {
        return acc + supply.tax; // Sumamos el costo del IVA de cada insumo
      }, 0);

      const total = subtotal + tax;
      return {
        subtotal,
        tax,
        total,
      };
    },
    resetFormSupply() {
      this.form_request_supply = {
        supply: null,
        unit: null,
        unitRequest: null,
        unitCost: null,
        total: null,
        tax: null,
        neto: null,
        taxObject: {
          value: 1.19,
          item: '19%'
        }
      }
    },
    resetFormRequestSupply() {
      this.form_request_supply = {
        supply: null,
        unit: null,
        unitRequest: null,
        unitCost: null,
        total: null,
        tax: null,
        neto: null,
        taxObject: {
          value: 1.19,
          item: '19%'
        }
      }
      this.form_purchase_order = {
        id: null,
        showed: false,
        provider_id: '',
        status: '',
        document: '',
        countSupplies: '',
        createdAt: '',
        deliveryAt: '',
        reference: '',
        note: '',
        total: '',
        tax: '',
        subtotal: '',
        supplies: [],
        priority: '',
        warehouse_id: '',
      }
      this.errors = {};
      this.form_purchase_order.supplies = [];
      this.form_purchase_order.showed = false;
    },
    async storeOrderPurchase(orderPurchase) {
      const response = await apiOrderPurchase.createOrderPurchase(orderPurchase);
    },

    // ATTACH SUPPLY TO PURCHASE ORDER
    addSupply(supplyData) {
      this.form_purchase_order.supplies.push(supplyData);

      this.form_request_supply = {
        supply: null,
        unit: null,
        unitRequest: null,
        unitCost: null,
        total: null,
        tax: null,
        neto: null,
        taxObject: {
          value: 1.19,
          item: '19%'
        }
      };

    },
    readSupply(supplyId) {
      this.form_request_supply = {
        ...this.form_purchase_order.supplies.find(supply => supply
          .supply.id ===
          supplyId)
      };
    },

    updateSupply(id, updatedSupply) {

      let targetSupplies = this.form_purchase_order.supplies.map(supply => {

        if (supply.supply.id === id) {
          return {
            ...updatedSupply
          }
        };
        return supply;
      });

      this.form_purchase_order.supplies = targetSupplies;
    },

    deleteSupply(supplyId) {
      const index = this.form_purchase_order.supplies.findIndex(supply => supply.supply
        .id ===
        supplyId);

      this.form_purchase_order.supplies.splice(index, 1)
    },
    // END ATTACH SUPPLY TO PURCHASE ORDER

    async updatePurchaseOrderStatus(id, status) {
      const purchaseOrder = this.orderPurchases.find(po => po.id === id);
      if (!purchaseOrder) {
        throw new Error(`Purchase order with ID ${id} not found`);
      }

      try {
        await apiOrderPurchase.updateOrderPurchaseStatus(id, status);

        purchaseOrder.status = status;
      } catch (error) {
        console.error(error);
        throw new Error(`Failed to update purchase order status: ${error.message}`);
      }
    },
    async fetchOrderPurchaseById(id) {
      this.form_purchase_order.showed = true;
      try {
        const response = await apiOrderPurchase.fetchOrderPurchaseById(id);
        this.form_purchase_order.id = response.data.id;
        this.form_purchase_order.provider_id = response.data.provider_id;
        this.form_purchase_order.status = response.data.status;
        this.form_purchase_order.document = response.data.document;
        this.form_purchase_order.countSupplies = response.data.supplies.length;
        this.form_purchase_order.createdAt = moment(response.data.created_at).format(
          'YYYY-MM-DD');
        this.form_purchase_order.deliveryAt = moment(response.data.deliveryAt).format(
            'YYYY-MM-DD'),
          this.form_purchase_order.reference = response.data.reference;
        this.form_purchase_order.note = response.data.note;
        this.form_purchase_order.priority = response.data.priority;


        this.form_purchase_order.supplies = response.data.supplies.map(supply => {

          let targetTaxItem = supply.pivot.tax_type === 0 ? parseFloat(supply.pivot
              .tax_type) :
            supply.pivot.tax_type;

          const taxItem = this.taxItems.find(
            (item) => item.otherValue == targetTaxItem
          );
    
          return {
            supply: {
              ...supply
            },
            unit: supply.pivot.quantity,
            unitRequest: parseInt(supply.pivot.quantity),
            unitCost: supply.pivot.unit_cost,
            total: supply.pivot.total_cost,
            tax: supply.pivot.tax_type,
            neto: parseInt(supply.pivot.net_cost) * parseInt(supply.pivot.quantity),
            taxObject: taxItem
          }
        })
        const {
          total,
          subtotal,
          tax
        } = this.calculateTotals();
        this.form_purchase_order.total = total;
        this.form_purchase_order.subtotal = subtotal;
        this.form_purchase_order.tax = tax;

      } catch (error) {
        console.error(error);
        // handle error...
      }
    },
  },
});
