// src/services/api.js
import axios from '@/plugins/axios';

export default {
  getOrderPurchases(warehouseId) {
    const params = {
      warehouse: warehouseId,
    };
    return axios.get('/v1/order_purchase', {
      params,
    });
  },
  fetchOrderPurchaseById(orderPurchaseId) {
    return axios.get(`/v1/order_purchase/${orderPurchaseId}`);
  },
  createOrderPurchase(orderPurchaseId) {
    return axios.post('/v1/order_purchase', orderPurchaseId);
  },

  updateOrderPurchase(orderPurchaseId, updatedOrderPurchase) {
    return axios.put(`/v1/order_purchase/${orderPurchaseId}`, updatedOrderPurchase);
  },

  deleteOrderPurchase(orderPurchaseId) {
    return axios.delete(`/v1/order_purchase/${orderPurchaseId}`);
  },
  updateOrderPurchaseStatus(orderPurchaseId, status) {
    return axios.put(`/v1/order-purchase-update-status/${orderPurchaseId}`, {
      status,
    });
  },
};
