// src/services/api.js
import axios from '@/plugins/axios';

export default {
  getProviders() {
    return axios.get('/v1/providers');
  },
  getProvider(providerId) {
    return axios.get(`/v1/providers/${providerId}`);
  },
  createInvoce(invoice) {
    return axios.post('/v1/invoice', invoice);
  },

  updateInvoice(invoiceId, updatedInvoice) {
    return axios.put(`/v1/invoice/${invoiceId}`, updatedInvoice);
  },

  deleteProvider(providerId) {
    return axios.delete(`/v1/providers/${providerId}`);
  },

  getInvoicesWorkOrder(workOrderId) {
    return axios.get(`/v1/invoice/work-order/${workOrderId}`);
  },
};
