<template>
  <div>
    <v-list>
      <v-list-item>
        <v-icon class="mr-3" @click="handleBack()">mdi-arrow-left</v-icon>
        <v-text-field
          :disabled="selectAll"
          v-model="useWarehouseStore.params.search"
          color="orange"
          label="Buscar insumo"
          required
          solo
          hide-details
          @input="debouncedFetchSupplies"
        ></v-text-field>
        <v-btn
          :disabled="
            useWarehouseStore.supplies_list_selectable.filter((el) => el.selectable).length <= 0
          "
          class="ml-3"
          :loading="loader"
          small
          color="orange"
          dark
          elevation="0"
          @click="attachSupplies()"
          >Guardar</v-btn
        >
        <slot name="header"></slot>
      </v-list-item>
      <v-divider></v-divider>
      <v-progress-linear
        v-if="useWarehouseStore.loader_attach_supplies_search"
        color="orange"
        indeterminate
        reverse
      ></v-progress-linear>
      <div v-if="useWarehouseStore.supplies_list_selectable.length != 0">
        <v-list-item :disabled="useWarehouseStore.loader_attach_supplies_search">
          <v-list-item-action>
            <v-checkbox
              :disabled="useWarehouseStore.loader_attach_supplies_search"
              v-model="selectAll"
              color="orange"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-title class="text-caption">Seleccionar todo</v-list-item-title>
        </v-list-item>
      </div>
      <div class="text-caption mx-5" v-if="selectAll">
        {{ useWarehouseStore.totalSuppliesAvailables }} insumo(s) seleccionado(s)
      </div>
      <div class="text-caption mx-5" v-if="!selectAll &&
        useWarehouseStore.stock_supply_selected.filter(el => el.selectable).length >= 1">
        {{useWarehouseStore.stock_supply_selected.
        filter(el => el.selectable).length }} insumo(s) seleccionado(s)
      </div>
      <div>
        <div
          class="text-caption text-center mt-5"
          v-if="
            useWarehouseStore.loader_attach_supplies &&
            !useWarehouseStore.loader_attach_supplies_search
          "
        >
          Cargando insumos, espere un momento porfavor...⏳
        </div>
        <div
          v-if="
            !useWarehouseStore.loader_attach_supplies &&
            useWarehouseStore.supplies_list_selectable.length === 0
          "
          class="text-caption text-center mt-5"
        >
          No se encuentran insumos disponibles
        </div>
        <v-list-item
          :disabled="selectAll"
          selectable
          multiple
          v-for="(item, index) in useWarehouseStore.supplies_list_selectable"
          :key="index"
          :value="item"
        >
          <v-list-item-action>
            <v-checkbox
              :disabled="selectAll"
              v-model="item.selectable"
              v-on:change="selectionChanged(item)"
              color="orange"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              ><v-icon :disabled="useWarehouseStore.loader_attach_supplies_search"
                >mdi-package-variant</v-icon
              >
              {{ item.name }}</v-list-item-title
            >
            <div>
              <div class="w-50 d-flex justify-space-between caption mt-2">
                <div>Tipo</div>
                <div>{{ item.category ? item.category.name : 'SIN CATEGORIA' }}</div>
              </div>
              <div class="w-50 d-flex justify-space-between caption">
                <div>Código</div>
                <div>{{ item.id }}</div>
              </div>
              <div class="w-50 d-flex justify-space-between caption">
                <div>Precio unitario</div>
                <div>
                  <strong> ${{ item.cost }}</strong>
                </div>
              </div>
              <div class="w-50 d-flex justify-space-between caption">
                <div>Ubicación</div>
                <div>
                  <strong> {{ item.workshop.name }}</strong>
                </div>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
        <div style="padding: 1em">
          <v-btn
            :disabled="selectAll"
            v-if="useWarehouseStore.last_page != 1"
            @click="loadMoreSupplies()"
            color="orange"
            dark
            outlined
            small
            block
            :loading="useWarehouseStore.loader_attach_supplies"
          >
            Cargar más
          </v-btn>
        </div>
      </div>
    </v-list>
  </div>
</template>
<script>
/* eslint-disable */
import InventoryService from '@/inventory/services/InventoryService';
import { useAppStore } from '@/store/app';
import { eventBus } from '../../main';
import { useWarehouseStore } from '@/warehouse/store/warehouse';

export default {
  data: () => ({
    store: useAppStore(),
    useWarehouseStore: useWarehouseStore(),
    search: '',
    loader: false,
    selectAll: false,
  }),
  computed: {
    debouncedFetchSupplies() {
      return this.handlefetchSuppliesListSelectable;
    },
  },
  watch: {
    selectAll: function (value) {
      this.useWarehouseStore.supplies_list_selectable.map((p) => {
        return (p.selectable = value);
      });

      if (!value) {
        this.useWarehouseStore.stock_supply_selected = [];
      }
    },
    'item.selectable': function (value) {
      const index = this.useWarehouseStore.supplies_list_selectable.findIndex(
        (supply) => value.id === supply.id
      );

      if (index !== -1) {
        this.useWarehouseStore.supplies_list_selectable[index] = {
          ...value,
          selectable: !value.selectable,
        };
      }
      console.log(this.useWarehouseStore.supplies_list_selectable.filter(el => el.selectable).length);
      this.useWarehouseStore.totalSuppliesSelected = this.useWarehouseStore.supplies_list_selectable.filter(el => el.selectable).length
    },
  },
  created() {
    this.handlefetchSuppliesListSelectable();
  },
  methods: {
    selectionChanged(item) {
      this.useWarehouseStore.stock_supply_selected = this.useWarehouseStore.getSelectedSupplies();
    },
    handleBack() {
      this.store.generalDrawer = false;
      this.store.currentForm = '';
      this.useWarehouseStore.supplies_list_selectable = [];
      this.useWarehouseStore.stock_supply_selected = [];
      this.useWarehouseStore.params.search = '';
      this.useWarehouseStore.params.perPage = 5;
      this.selectAll = false;
    },
    loadMoreSupplies() {
      this.useWarehouseStore.params.perPage = this.useWarehouseStore.params.perPage + 5;
      this.useWarehouseStore.fetchSuppliesListSelectable(this.$route.params.id);
    },
    handlefetchSuppliesListSelectable() {
      let id = this.$route.params.id;
      this.useWarehouseStore.fetchSuppliesListSelectable(id);
    },
    async attachSupplies() {
      this.loader = true;
      let id = this.$route.params.id;
      await this.useWarehouseStore.attachSupplyToWarehouse(
        id,
        this.useWarehouseStore.supplies_list_selectable.filter((el) => el.selectable),
        this.selectAll
      );

      this.useWarehouseStore.fetchStock(id);

      this.store.openSnackbar('Insumo agregado correctamente ✅');

      this.loader = false;

      this.useWarehouseStore.stock_supply_selected = [];

      this.useWarehouseStore.supplies_list_selectable = [];

      this.useWarehouseStore.fetchSuppliesListSelectable(id);
    },
    toggleCompleteSelection() {
      if (this.noNodesSelected || this.someNodesSelected) {
        this.useWarehouseStore.stock_supply_selected =
          this.useWarehouseStore.supplies_list_selectable.map((node) => node.id);
      } else {
        this.useWarehouseStore.stock_supply_selected = [];
      }
    },
  },
};
</script>
