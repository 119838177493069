<!-- eslint-disable max-len -->
<template>
  <v-container fluid class="p-5">
    <div elevation="0" class="d-flex justify-space-between align-centerP my-2">
      <div>
        <v-btn icon dark @click="handleBack()">
          <v-icon color="grey">mdi-chevron-left</v-icon></v-btn
        >
        {{ this.useWarehouseStore.transactionType === 'entry' ? 'Nueva entrada' : 'Nueva salida' }}
      </div>
      <v-btn
        :loading="loader"
        :disabled="loader"
        @click="handleStoreTransaction()"
        elevation="0"
        small
        dark
        color="orange"
        dense
        >Guardar</v-btn
      >
    </div>
    <br />
    <v-alert
        v-if="useTransaction.errors.supplies"
        outlined
        type="warning"
        prominent
        border="left"
        dense
        small
      >
        En necesario ingresar al menos un insumo para poder guardar la entrada.
      </v-alert>
      <br>
    <v-row>
      <v-col class="py-0">
        <v-select
          label="Tipo de movimiento"
          :items="useTransaction.typeTrasactionsEntry"
          item-value="id"
          item-text="name"
          v-model="useTransaction.form_transaction.typeTrasactionId"
          outlined
          :error-messages="
            useTransaction.errors.typeTrasactionId && useTransaction.errors.typeTrasactionId[0]
          "
        />
      </v-col>
      <v-col cols="3" class="py-0" v-if="this.useWarehouseStore.transactionType === 'entry'">
        <v-select
          label="Boleta/Factura"
          :items="[
            { id: 'invoice', name: 'Boleta' },
            { id: 'bill', name: 'Factura' },
          ]"
          item-value="id"
          item-text="name"
          v-model="useTransaction.form_transaction.typeInvoice"
          outlined
          :error-messages="
            useTransaction.errors.typeInvoice && useTransaction.errors.typeInvoice[0]
          "
        />
      </v-col>
      <v-col cols="3" class="py-0">
        <v-text-field
          label="Documento"
          v-model="useTransaction.form_transaction.document"
          outlined
          :error-messages="
            useTransaction.errors.document && useTransaction.errors.document[0]
          "
        />
      </v-col>
      <v-col cols="3" class="py-0">
        <v-text-field
          type="date"
          label="Fecha de entrega"
          v-model="useTransaction.form_transaction.date"
          outlined
          :error-messages="
            useTransaction.errors.date_delivery && useTransaction.errors.date_delivery[0]
          "
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="py-0" v-if="this.useWarehouseStore.transactionType === 'entry'">
        <v-select
          label="Tipo de pago"
          :items="[
            { id: 'Credit', name: 'Crédito' },
            { id: 'Cash', name: 'Efectivo' },
          ]"
          item-value="id"
          item-text="name"
          v-model="useTransaction.form_transaction.payment_type"
          outlined
          :error-messages="
            useTransaction.errors.payment_type && useTransaction.errors.payment_type[0]
          "
        />
      </v-col>
      <v-col cols="3" class="py-0">
        <v-text-field
          label="Referencia"
          v-model="useTransaction.form_transaction.reference"
          outlined
        />
      </v-col>
      <v-col cols="6" class="py-0">
        <v-autocomplete
          v-model="useTransaction.form_transaction.providerId"
          label="Proveedor"
          outlined
          item-value="id"
          :items="useTransaction.providers"
          :error-messages="
            useTransaction.form_transaction.providerId &&
            useTransaction.form_transaction.providerId[0]
          "
         :filter="customFilter"
        >
          <template slot="selection" slot-scope="data">
            {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="{ item }">
            <v-avatar color="orange" size="40" style="color: white">{{ item.name[0] }}</v-avatar>
            <div class="fullName d-flex flex-column ml-2">
              <div>{{ item.name }}</div>
              <div>
                <span class="text-caption">{{ item.address }}</span>
              </div>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-textarea rows="2" label="Nota" v-model="useTransaction.form_transaction.note" outlined />
      </v-col>
    </v-row>
    <dialog-confirm ref="confirm"></dialog-confirm>
  </v-container>
</template>

<script>
import DialogConfirm from '@/components/DialogConfirm.vue';
import { customFilter } from '@/worker_order/services/helpers';
import { useAppStore } from '@/store/app';
import { useWarehouseStore } from '../store/warehouse';
import { useOrderPurchase } from '../store/orderPurchase';
import { useTransaction } from '../store/transaction';

export default {
  components: {
    DialogConfirm,
  },
  data() {
    return {
      useWarehouseStore: useWarehouseStore(),
      useOrderPurchase: useOrderPurchase(),
      useAppStore: useAppStore(),
      useTransaction: useTransaction(),
      loader: false,
    };
  },
  mounted() {
    this.useTransaction.fetchTypesTrasactions(this.useWarehouseStore.transactionType);
  },
  created() {
    this.useTransaction.fetchProviders();
  },
  methods: {
    customFilter,
    isPurchaseOrderRoute() {
      return this.$route.path.includes('purchases-orders');
    },
    hasEmptyForm() {
      if (this.isPurchaseOrderRoute()) {
        return (
          this.useTransaction.form_transaction.date === ''
          && this.useOrderPurchase.form_purchase_order.document === ''
          && this.useOrderPurchase.form_purchase_order.reference === ''
          && this.useOrderPurchase.form_purchase_order.note === ''
          && this.useOrderPurchase.form_purchase_order.provider_id === ''
          && this.useOrderPurchase.form_purchase_order.supplies.length === 0
        );
      }
      return (
        this.useTransaction.form_transaction.date === null
        && this.useTransaction.form_transaction.document === null
        && this.useTransaction.form_transaction.reference === null
        && this.useTransaction.form_transaction.note === null
        && this.useTransaction.form_transaction.providerId === null
        && this.useTransaction.form_transaction.typeTrasactionId === null
        && this.useTransaction.form_transaction.typeInvoice === null
        && this.useOrderPurchase.form_purchase_order.supplies.length === 0
      );
    },
    async handleBack() {
      if (this.hasEmptyForm()) {
        this.useAppStore.toogleGeneralDialog(false, null);
        this.useTransaction.resetTransaction();
        this.useOrderPurchase.form_purchase_order.supplies = [];
        const { total, subtotal, tax } = this.useOrderPurchase.calculateTotals();
        this.useOrderPurchase.form_purchase_order.total = total;
        this.useOrderPurchase.form_purchase_order.subtotal = subtotal;
        this.useOrderPurchase.form_purchase_order.tax = tax;
        this.useTransaction.errors = {};
      } else if (
        await this.$refs.confirm.open(
          '¿Está seguro que desea salir del formulario sin guardar los cambios?',
          'Si sale del formulario ahora, perderá cualquier cambio que haya realizado. Para continuar escribiendo en el formulario, presione el botón SEGUIR EDITANDO. De lo contrario, presione el botón SALIR para salir del formulario',
          'SEGUIR EDITANDO',
          'SALIR',
          500,
        )
      ) {
        this.useAppStore.toogleGeneralDialog(false, null);
        this.useTransaction.resetTransaction();
        this.useOrderPurchase.form_purchase_order.supplies = [];
        const { total, subtotal, tax } = this.useOrderPurchase.calculateTotals();
        this.useOrderPurchase.form_purchase_order.total = total;
        this.useOrderPurchase.form_purchase_order.subtotal = subtotal;
        this.useOrderPurchase.form_purchase_order.tax = tax;
        this.useTransaction.errors = {};
      }
    },
    async handleStoreTransaction() {
      this.loader = true;
      // eslint-disable-next-line operator-linebreak
      this.useTransaction.form_transaction.supplies =
        this.useOrderPurchase.form_purchase_order.supplies;
      const warehouse = this.$route.params.id;
      this.useTransaction.form_transaction.transaction = this.useWarehouseStore.transactionType;
      try {
        await this.useTransaction.storeTransaction(warehouse, this.useTransaction.form_transaction);
        await this.useTransaction.fetchTrasactions(
          this.useWarehouseStore.transactionType,
          warehouse,
        );
        this.useOrderPurchase.form_purchase_order.supplies = [];
        this.useAppStore.toogleGeneralDialog(false, this.useAppStore.currentFormDialog);
        this.useAppStore.openSnackbar('Entrada ingresada correctamente  ✅');
        this.useTransaction.resetTransaction();
        const { total, subtotal, tax } = this.useOrderPurchase.calculateTotals();
        this.useOrderPurchase.form_purchase_order.total = total;
        this.useOrderPurchase.form_purchase_order.subtotal = subtotal;
        this.useOrderPurchase.form_purchase_order.tax = tax;
        this.useTransaction.errors = {};
        this.useTransaction.fetchTrasactions('entry', this.$route.params.id);
      } catch (error) {
        this.loader = false;
        console.log(error);
        const originalErrors = error.response.data.errors;

        const modifiedErrors = Object.keys(originalErrors).reduce((acc, key) => {
          const newKey = this.removeTransactionPrefix(key);
          acc[newKey] = originalErrors[key];
          return acc;
        }, {});
        this.useTransaction.errors = modifiedErrors;
        this.useAppStore.openSnackbar('Error al crear entrada  ❌');
      }

      this.loader = false;
    },
    removeTransactionPrefix(key) {
      return key.replace(/^transaction\./, '');
    },
  },
};
</script>
