<template>
  <v-navigation-drawer
    v-model="store.drawer_filter"
    absolute
    temporary
    fixed
    width="400"
    right
  >
    <v-list-item>
      <v-icon class="mr-3" @click="store.toggleDrawerFilterRight()">mdi-arrow-left</v-icon>
      Volver
    </v-list-item>
    <v-divider></v-divider>
    <v-container>
      <filter-supplies-form
        v-if="(store.drawer_filter_content === 'filter_supply')"
      ></filter-supplies-form>
    </v-container>
  </v-navigation-drawer>
</template>
<script>
/* eslint-disable */
import { useAppStore } from "@/store/app";
import FilterSuppliesForm from "@/inventory/components/FilterSuppliesForm.vue";
export default {
  components: { FilterSuppliesForm },
  data: () => ({
    store: useAppStore(),
  }),
  methods: {},
};
</script>
