/* eslint-disable */
import axios from '@/plugins/axios'

export default {

  getTrucks() {
    return axios.get('/v2/trucks');
  },
  getCoupled() {
    return axios.get('/v1/get-coupled');
  },
  getUsers() {
    return axios.get('/v2/users');
  },
  getSpecialists() {
    return axios.get('/v2/specialists');
  },
  getDrivers() {
    return axios.get('/v2/drivers');
  },
  getLocations() {
    return axios.get('/v2/locations_by_company');
  },
  getAllOts() {
    return axios.get('/v2/order_workers?page[size]=*');
  },
  getOtsById(WorkOrderId) {
    return axios.get(`/v2/order_workers_with_warehouse/${WorkOrderId}`);
  },
  createOts(WorkOrderId) {
    return axios.post('/v2/order_workers_with_warehouse', WorkOrderId);
  },
  updateOts(WorkOrderId, WorkOrder) {
    return axios.put(`/v2/order_workers_with_warehouse/${WorkOrderId}`, WorkOrder);
  },
  deleteSupplyOrderWork(id){
    return axios.delete(`/v2/order_workers_with_warehouse/supply/${id}`);
  },
  deleteOts(WorkOrderId) {
    return axios.delete(`/v2/order_workers_with_warehouse/${WorkOrderId}`);
  },
  syncCards(data) {
    return axios.put(`/v2/sync_cards`, {
      works_orders: data
    });
  },
  loadInitialPositions(initialPositions) {
    return axios.put(`/load_initial_positions`, {
      initialPositions: initialPositions
    });
  },
  getOtsByStatus(params) {
    let targetParams = {
      ...params
    }

    if (targetParams.from != '') {
      targetParams.from = targetParams.from + ' 00:00:00'
    }
    if (targetParams.to != '') {
      targetParams.to = targetParams.to + ' 23:59:59'
    }

    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'from' || key == 'to') {
          targetFilter['filter[BetweenDate]'] = targetParams.from + ',' + targetParams.to
        }

        if (key == 'truck') {
          targetFilter['filter[Truck]'] = targetParams.truck
        }
        if (key == 'location') {
          targetFilter['filter[WorkshopId]'] = targetParams.location.id
        }
        if (key === 'planned') {
          targetFilter['filter[Planned]'] = targetParams.planned
        }
      }
    }



    return axios.get(`/order_workers_by_group`, {
      params: targetFilter
    });
  },
  updateStatusOts(WorkOrderId, status, positions) {

    return axios.put(`/update_status/${WorkOrderId}`, {
      status: status,
      positions: positions
    });
  },
  getHistoryOrderWorks(params) {

    let targetParams = {
      ...params
    }

    if (targetParams.from != '') {
      targetParams.from = targetParams.from + ' 00:00:00'
    }
    if (targetParams.to != '') {
      targetParams.to = targetParams.to + ' 23:59:59'
    }

    let targetFilter = {}

    for (let key in targetParams) {
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'from' || key == 'to') {
          targetFilter['filter[BetweenDate]'] = targetParams.from + ',' + targetParams.to
        }

        if (key == 'truck') {
          targetFilter['filter[Truck]'] = targetParams.truck
        }
        if (key == 'location') {
          targetFilter['filter[WorkshopId]'] = targetParams.location.id
        }
      }
    }

    return axios.get(`/history_order_workers?page[size]=*`, {
      params: targetFilter
    });
  },
};
