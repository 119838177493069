<template>
  <div>

    <v-container fluid>
      <div>
      <v-btn icon @click="appStore.toogleGeneralDrawer(true, 'form-service-provider')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      Nueva categoría
      </div>
      <br>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="12">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field v-model="serviceCategory.name"
            label="Nombre" required outlined dense
            :error-messages="errors.name && errors.name[0]"></v-text-field>

            <v-text-field v-model="serviceCategory.description"
            label="Descripción" outlined dense
            :error-messages="errors.name && errors.name[0]"></v-text-field>

            <v-btn block color="orange" elevation="0" dark
            @click="handleCreateCategory">Guardar</v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { useAppStore } from '@/store/app';
import { useProviderStore } from '@/provider/store/provider';

export default {
  data() {
    return {
      appStore: useAppStore(),
      useProviderStore: useProviderStore(),
      valid: true,
      serviceCategory: {
        name: '',
        description: '',
        workshop_id: '',
        company_id: '',
      },
      errors: {},
    };
  },
  methods: {
    async handleCreateCategory() {
      try {
        this.serviceCategory.workshop_id = JSON.parse(localStorage.getItem('workshop')).id;
        this.serviceCategory.company_id = JSON.parse(localStorage.getItem('company')).id;
        await this.useProviderStore.createCategoryService(this.serviceCategory);
        this.appStore.toogleGeneralDrawer(true, 'form-service-provider');
        this.appStore.openSnackbar('Categoría creada correctamente ✅');
        this.useProviderStore.fetchCategoriesService();
      } catch (error) {
        if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        } else {
          this.appStore.openSnackbar('Error al crear la categoría ❌');
        }
      }
    },
  },
};
</script>
