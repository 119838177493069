/* eslint-disable comma-dangle */
import axios from 'axios';
// Configuración de Axios
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;

// eslint-disable-next-line dot-notation
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

// Interceptor de errores
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && window.location.pathname !== '/login') {
      // Redirigir a la página de inicio de sesión si no hay autorización
      window.location = '/login';
    }

    if (error.response.status === 503 && window.location.pathname !== '/maintenance') {
      // Redirigir a la página de inicio de sesión si no hay autorización
      window.location = '/maintenance';
    }
    return Promise.reject(error);
  }
);

export default axios;
