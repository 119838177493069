<template>
  <v-row justify="center">
    <v-dialog
      color="red"
      v-model="store.generalDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      >
      <slot name="container"></slot>
      <v-card>
        <slot name="container-form"></slot>
        <v-divider></v-divider>
        <slot name="content"></slot>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { useAppStore } from '@/store/app';

export default {
  data() {
    return {
      store: useAppStore(),
    };
  },
};
</script>
