/* eslint-disable */
import axios from '@/plugins/axios'

export default {

  getTrucksList(params){
    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {

      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key == 'page') {
          targetFilter.page = targetParams.page;
        }
        if (key == 'per_page') {
          targetFilter.per_page = targetParams.per_page;
        }
        if (key == 'search') {
          targetFilter['filter[Search]'] = targetParams.search && targetParams.search;
        }
      }
    }

    return axios.get('/v1/list-trucks', {
      params: targetFilter
    });
  },
  getTrucks(params) {

    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {

      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {

        if (key == 'search') {
          targetFilter['filter[Search]'] = targetParams.search && targetParams.search
        }
        if (key == 'val') {
          targetFilter['page'] = targetParams.val && targetParams.val.page
        }

      }
    }

    return axios.get('/v1/trucks', {
      params: targetFilter
    });
  },

  getSuppliesFromWarehouse(warehouse,params) {
    return axios.get(`/v1/supplie_from_warehouse`, {
      params: {
        warehouseId: warehouse,
        page: params.page,
        perPage: params.perPage,
        search: params.search,
      }
    });
  },
  getSupplie(id) {
    return axios.get(`/v1/supplies/${id}`);
  },
  getSuppliesbyWorkshop(workshop,params) {

    let targetParams = {
      ...params
    }

    let targetFilter = {}

    for (let key in targetParams) {

      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {

        if (key == 'search') {
          targetFilter['filter[Search]'] = targetParams.search && targetParams.search
        }
      }
      if (workshop) {
        targetFilter['filter[WorkshopId]'] = workshop
      }

    }

    return axios.get(`/v1/supplies`, {
      params: targetFilter
    });
  },
  getOtsById(WorkOrderId) {
    return axios.get(`/v1/order_workers/${WorkOrderId}`);
  },
  createTruck(payload) {
    return axios.post('/v1/trucks', payload);
  },
  updateTruck(truckId, truck) {
    return axios.put(`/v1/trucks/${truckId}`, truck);
  },
  updateSupplies(supplieId, supplie) {
    return axios.put(`/v1/supplies/${supplieId}`, supplie);
  },
  deleteSupplies(WorkOrderId) {
    return axios.delete(`/v1/supplies/${WorkOrderId}`);
  },

  getMeasurementUnits(workshopSelected) {
    let path = workshopSelected ?
      `/v1/measurement_units?filter[WorkshopId]=${workshopSelected}` :
      '/v1/measurement_units'
    return axios.get(path);
  },

  getCategories(workshopSelected) {
    let path = workshopSelected ?
      `/v1/categories?filter[WorkshopId]=${workshopSelected}` :
      '/v1/categories'
    return axios.get(path);
  },
  deleteSuppliesImported(val) {
    return axios.delete(`/v1/supplies-delete-imported`,{
      params : {
        hash_import : val
      }
    });
  },
  getBrands() {
    return axios.get('/v1/getBrands');
  },

  getTypeOfTrucks() {
    return axios.get('/v1/getTypeOfTruck');
  },
  getTruck(id) {
    return axios.get(`/v1/trucks/${id}`);
  },
  deleteTruck(id) {
    return axios.delete(`/v1/trucks/${id}`);
  },
  createCoupled(coupled) {
    return axios.post('/v1/coupled', coupled);
  },
  createBrand(brand) {
    return axios.post('/v1/brands', brand);
  },
  createTypeOfTruck(typeOfTruck) {
    return axios.post('/v1/type_of_truck', typeOfTruck);
  },
  getTypeOfTrucks() {
    return axios.get('/v1/type_of_truck');
  },
  getCenterCosts() {
    return axios.get('/v1/center_costs');
  },
  createCenterCost(centerCost) {
    return axios.post('/v1/center_costs', centerCost);
  }
};
