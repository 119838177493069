<template>
  <v-container fluid>
    <div v-if="showToogle">
      <v-btn icon @click="appStore.toogleGeneralDrawer(false, ''); resetService()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      Nuevo servicio
      </div>
      <br v-if="showToogle">
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-form ref="form" >
          <template v-if="showCreateCategory">
            <v-select
              v-model="providerStore.service.provider_id"
              item-text="name"
              item-value="id"
              label="Proveedor"
              :items="providerStore.providersByWorkshop"
              required
              outlined
              dense
              :error-messages="errors.provider_id && errors.provider_id[0]"
            ></v-select>
          </template>
          <v-text-field
            v-model="providerStore.service.code"
            label="Código"
            required
            outlined
            dense
            :error-messages="errors.code && errors.code[0]"
          ></v-text-field>

          <v-text-field
            v-model="providerStore.service.name"
            label="Nombre"
            required
            outlined
            dense
            :error-messages="errors.name && errors.name[0]"
          ></v-text-field>

          <v-text-field
            v-model="providerStore.service.description"
            label="Description"
            required
            outlined
            dense
            :error-messages="errors.description && errors.description[0]"
          ></v-text-field>

          <v-text-field
            v-model="providerStore.service.unit_price"
            label="Costo unitario"
            required
            outlined
            dense
            :error-messages="errors.unit_price && errors.unit_price[0]"
          ></v-text-field>

          <template>
            <v-select
              v-model="providerStore.service.category_id"
              item-text="name"
              item-value="id"
              label="Categoria"
              :items="providerStore.categories"
              required
              outlined
              append-outer-icon="mdi-plus-circle-outline"
              @click:append-outer="
              appStore.toogleGeneralDrawer(true, 'form-provider-service-category')"
              dense
              :error-messages="errors.category_id && errors.category_id[0]"
            ></v-select>
          </template>
          <v-btn v-if="!providerStore.service.id" color="orange" dark block
          :loading="loading"
          elevation="0" @click="handleCreateServiceProvider">Guardar</v-btn>
          <v-btn v-if="providerStore.service.id" color="orange" dark block
          :loading="loading"
          elevation="0" @click="handleUpdateServiceProvider">Actualizar</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useProviderStore } from '@/provider/store/provider';
import { useAppStore } from '@/store/app';

export default {
  props: {
    showToogle: Boolean,
    showCreateCategory: {
      type: Boolean,
      default: true,
    },
    closeAfterCreateOrUpdate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      providerStore: useProviderStore(),
      appStore: useAppStore(),
      valid: true,
      loading: false,
      errors: {},
    };
  },
  created() {
    this.providerStore.fetchCategoriesService();
  },
  methods: {
    async handleUpdateServiceProvider() {
      this.loading = true;
      try {
        await this.providerStore.updateServiceProvider(
          this.providerStore.service.id,
          this.providerStore.service,
        );
        await this.providerStore.fetchServiceProviders(
          this.providerStore.selectedProvider
            ? this.providerStore.selectedProvider.id : this.$route.params.id,
        );
        this.appStore.openSnackbar('Servicio actualizado correctamente ✅', 'success');
        this.providerStore.service = {
          id: '',
          code: '',
          name: '',
          description: '',
          unit_price: '',
          provider_id: '',
          workshop_id: '',
          category_id: '',
          company_id: '',
        };
        this.errors = {};
        this.$emit('update');
        if (this.closeAfterCreateOrUpdate) {
          this.appStore.toogleGeneralDrawer(false, '');
        }
      } catch (error) {
        if (error.response && error.response.status) {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          } else {
            this.appStore.openSnackbar('Error al actualizar el servicio ❌');
          }
        } else {
          console.log(error);
          this.appStore.openSnackbar('Error al actualizar el servicio ❌');
        }
      }
      this.loading = false;
    },
    async handleCreateServiceProvider() {
      this.loading = true;
      this.providerStore.service.workshop_id = JSON.parse(localStorage.getItem('workshop')).id;
      this.providerStore.service.company_id = JSON.parse(localStorage.getItem('company')).id;
      if (!this.showCreateCategory) {
        this.providerStore.service.provider_id = this.$route.params.id;
      }
      // eslint-disable-next-line max-len
      const targetServiceProvider = this.showCreateCategory ? this.providerStore.service.provider_id : this.$route.params.id;
      try {
        await this.providerStore.createServiceProvider(this.providerStore.service);
        await this.providerStore.fetchServiceProviders(targetServiceProvider);
        this.appStore.openSnackbar('Servicio creado correctamente ✅', 'success');
        this.providerStore.service = {
          id: '',
          code: '',
          name: '',
          description: '',
          unit_price: '',
          provider_id: '',
          workshop_id: '',
          category_id: '',
          company_id: '',
        };
        this.errors = {};
        this.$emit('create');
        if (this.closeAfterCreateOrUpdate) {
          this.appStore.toogleGeneralDrawer(false, '');
        }
      } catch (error) {
        if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        } else {
          this.appStore.openSnackbar('Error al crear la servicio ❌');
        }
      }
      this.loading = false;
    },
    resetService() {
      this.providerStore.selectedProvider = null;
      this.providerStore.service = {
        id: '',
        code: '',
        name: '',
        description: '',
        unit_price: '',
        provider_id: '',
        workshop_id: '',
        category_id: '',
        company_id: '',
      };
    },
  },
};
</script>
