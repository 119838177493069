<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <v-autocomplete
          :disabled="store.role != 'super-admin'"
          label="Ubicacion"
          outlined
          v-model="useInvertoryStore.filter_supplies.location"
          item-text="name"
          item-value="id"
          hide-details
          return-object
          clearable
          :items="useInvertoryStore.locations"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="12">
        <v-autocomplete
          label="Categorias"
          v-model="useInvertoryStore.filter_supplies.selectedCategories"
          :items="useInvertoryStore.categories"
          item-text="name"
          item-value="id"
          multiple
          outlined
          chips
          solo
          elevation="0"
          search=""
          color="orange"
          no-data-text="Categoria no encontrada"
        >
          >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item.name }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ useInvertoryStore.filter_supplies.selectedCategories.length - 1 }} seleccionadas)
            </span>
          </template>
          <template v-slot:prepend-item>
            <v-list-item ripple @mousedown.prevent @click="toggle">
              <v-list-item-action>
                <v-icon
                  :color="
                    useInvertoryStore.filter_supplies.selectedCategories.length > 0
                      ? 'indigo darken-4'
                      : ''
                  "
                >
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Seleccionar todas </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="12">
        <v-btn
          :loading="loading"
          block
          color="orange"
          elevation="0"
          style="color: white"
          @click="applyFilter()"
          >Aplicar filtro</v-btn
        >
        <v-btn text block color="orange" elevation="0" @click="clearFilter()" class="mt-2"
          >Limpiar filtro</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import { useAppStore } from "@/store/app";
import { eventBus } from "@/main";
import { useInvertoryStore } from "../store/inventory";
import InventoryService from "@/inventory/services/InventoryService";
export default {
  data: () => ({
    loading: false,
    store: useAppStore(),
    useInvertoryStore: useInvertoryStore(),
  }),
  computed: {
    likesAllCaterogies() {
      return (
        this.useInvertoryStore.filter_supplies.selectedCategories.length ===
        this.useInvertoryStore.categories.length
      );
    },
    likesSomeCategories() {
      return (
        this.useInvertoryStore.filter_supplies.selectedCategories.length > 0 &&
        !this.likesAllCaterogies
      );
    },
    icon() {
      if (this.likesAllCaterogies) return "mdi-close-box";
      if (this.likesSomeCategories) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllCaterogies) {
          this.useInvertoryStore.filter_supplies.selectedCategories = [];
        } else {
          this.useInvertoryStore.filter_supplies.selectedCategories =
            this.useInvertoryStore.categories.slice();
        }
      });
    },
    onFilter(item, queryText, itemText) {
      let search = item.code + "-" + item.patent;
      const textOne = search.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    async clearFilter() {
      this.useInvertoryStore.counterFilter = 0;
      this.useInvertoryStore.filter_supplies = this.useInvertoryStore.default_filter_supplies;
      this.store.toggleDrawerFilterRight();

      try {
        const response = await InventoryService.getSupplies(
          this.useInvertoryStore.default_filter_supplies
        );
        this.useInvertoryStore.supplies = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async applyFilter() {
      this.loading = true;
      try {
        const response = await InventoryService.getSupplies(this.useInvertoryStore.filter_supplies);

        this.useInvertoryStore.supplies = response.data.data;

        this.store.toggleDrawerFilterRight();

        this.countAttributesWithValues();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        // eslint-disable-next-line no-console
        console.log(error);
      }
      // handle the response data here
    },
    close() {
      this.handleFetchSupplies();
    },
    countAttributesWithValues() {
      let count = 0;
      for (let key in this.useInvertoryStore.filter_supplies) {
        if (this.store.role && this.store.role[0] != "super-admin") {
          if (
            this.useInvertoryStore.filter_supplies.hasOwnProperty(key) &&
            this.useInvertoryStore.filter_supplies[key] !== "" &&
            key !== "location" && 
            this.useInvertoryStore.filter_supplies[key].length !== 0
          ) {
            count++;
          }
        } else {
          if (
            this.useInvertoryStore.filter_supplies.hasOwnProperty(key) &&
            this.useInvertoryStore.filter_supplies[key] !== "" &&
            this.useInvertoryStore.filter_supplies[key].length !== 0
          ) {
            count++;
          }
        }
      }
      this.useInvertoryStore.counterFilter = count;
    },
  },
};
</script>
<style>
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
}
</style>
