/* eslint-disable */
// src/stores/warehouseStore.js
import api from '@/maintenance-alerts/services/MaintenanceAlertsService';

import { defineStore } from 'pinia';

export const useMaintenanceAlertsStore = defineStore('maintenanceAlertsStore', {
  state: () => ({
    trucks: [],
    activeCategory: null,
    categories: [],
    alerts: [],
    drawerCreateAlertIsOpen: true,
    filters: {
      search : '',
      location : null,
      trucks: [],
      categories: [],
      status: [],
    },
    formAlert: {
      id : null,
      isEdited: false,
      name: '',
      description: null,
      status: null,
      category_maintenance_alert_id: [],
      expirationSettings: {
        dateSelected: false,
        kilometersSelected: false,
        date: null,
        kilometers: null,
      },
      alertSettings: {
        daysSelected: false,
        kilometersSelected: false,
        days: null,
        kilometers_advance: null,
      },
      emails_to_notifications: {
        selected: [],
        suggestions: [''],
      },
      selectedTrucks: [],
    },
    errors: {
      name: null,
      description: null,
      category_maintenance_alert_id: [],
      expirationSettings: {
        date: null,
        kilometers: null,
      },
      alertSettings: {
        days: null,
        kilometers: null,
      },
      emails: null,
      selectedTrucks: null
    },
    filterSelected: [],
    filterSelectedCategories: [],
    filterSelectedStatus: [],
    combinedFilterSelected: [],
    loadingAlerts: false,
    countTotalAlerts: 0,
  }),

  actions: {
    async getCategories() {
      const response = await api.getCategories();
      this.categories = response.data;
    },

    async getAlerts(filter) {
      this.loadingAlerts = true;
      const response = await api.getAlerts(filter);
      this.countTotalAlerts = response.data.total;
      this.alerts = response.data.data;
      this.loadingAlerts = false;
      return response
    },

    async getTrucks() {
      const response = await api.getTrucks();
      this.trucks = response.data;
    },
    async storeAlert() {
        return api.storeAlert(this.formAlert);
    },
    setActiveCategory(category) {
      this.activeCategory = category;
    },
    deleteAlert(id) {
      return api.deleteAlert(id);
    },
    deleteManyAlerts(ids) {
      return api.deleteManyAlerts(ids);
    },
    updateAlert() {
      return api.updateAlert(this.formAlert);
    },
    clearFilters() {
      this.filterSelected = [];
      this.filterSelectedCategories = [];
      this.filterSelectedStatus = [];
      this.combinedFilterSelected = [];
      this.filters = {
        trucks: [],
        categories: [],
        status: [],
        location: null,
      };
      this.getAlerts();

      localStorage.removeItem('tableOptionsListAlertsCombinedFilterSelected');
    },
    updateStatusToEjecuted(id) {
      return api.updateStatusToEjecuted(id);
    },
    async handleGetEmails() {
      const response = await api.getEmails();
      console.log(response);

      this.formAlert.emails_to_notifications.suggestions = response.data;
    },
    resetFormAlert() {
      this.formAlert = {
        isEdited: false,
        name: '',
        description: null,
        category_maintenance_alert_id: [],
        expirationSettings: {
          dateSelected: false,
          kilometersSelected: false,
          date: null,
          kilometers: null,
        },
        alertSettings: {
          daysSelected: false,
          kilometersSelected: false,
          days: null,
          kilometers_advance: null,
        },
        emails_to_notifications: {
          selected: [],
          suggestions: [],
        },
        selectedTrucks: [],
      }
    },
    createCategoryAlert(category) {
      return api.storeCategoryAlert(category);
    },
    resetErrors() {
      this.errors = {
        name: null,
        description: null,
        category_maintenance_alert_id: [],
        expirationSettings: {
          date: null,
          kilometers: null,
        },
        alertSettings: {
          days: null,
          kilometers: null,
        },
        emails_to_notifications: {
          selected: [],
          suggestions: [],
        },
        selectedTrucks: null
      }
    },
  },
});
