// src/services/api.js
import axios from '@/plugins/axios';

export default {
  getTypeTransaction(type) {
    return axios.get('/v1/type-transaction', {
      params: {
        typeTransaction: type,
      },
    });
  },
  getTransactions(type, warehouseId, options) {
    const targetParams = {
      ...options,
    };
    const targetFilter = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const key in targetParams) {
      // eslint-disable-next-line no-prototype-builtins
      if (targetParams.hasOwnProperty(key) && targetParams[key] !== '') {
        if (key === 'date') {
          targetFilter['filter[BetweenDate]'] = `${targetParams.date[0]},${targetParams.date[1]}`;
        }
      }
    }

    return axios.get('/v1/transaction', {
      params: {
        ...options,
        ...targetFilter,
        typeTransaction: type,
        warehouse: warehouseId,
      },
    });
  },
  createTransaction(warehouseParam, transactionParam) {
    return axios.post('/v1/transaction', {
      warehouse: warehouseParam,
      transaction: transactionParam,
    });
  },
  verifyStock(supplyId, warehouseId, quantityParam) {
    return axios.get('/v1/verify-stock', {
      params: {
        supply: supplyId,
        warehouse: warehouseId,
        quantity: quantityParam,
      },
    });
  },
};
