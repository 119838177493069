<template>
  <div>
    <v-list>
      <v-list-item>
        <v-icon class="mr-3" @click="handleBack()">mdi-arrow-left</v-icon>
        <div class="mr-3"> alerta de mantenimiento</div>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>
    <div class="px-4">
      <div class="text-caption mb-3"> <strong>Información de alerta</strong></div>
      <v-row>
        <v-col cols="12" md="12" class="pb-0">
           <v-text-field
              color="orange"
              label="Nombre"
              outlined
              dense
              v-model="alertsStore.formAlert.name"
              :error-messages="alertsStore.errors.name
              && alertsStore.errors.name[0]"
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" class="pb-0">
          <v-autocomplete
            color="orange"
            dense
            small-chips
            outlined
            chips
            v-model="alertsStore.formAlert.category_maintenance_alert_id"
            label="Categoría"
            multiple
            return-object
            :item-text="(item) => item.name"
            :item-value="(item) => item.id"
            :items="alertsStore.categories"
            :error-messages="alertsStore.errors.category_maintenance_alert_id
              && alertsStore.errors.category_maintenance_alert_id[0]"
            append-outer-icon="mdi-plus-circle-outline"
            @click:append-outer="createCateroryMaintenanceAlert()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="12" class="pb-0">
          <v-textarea
            v-model="alertsStore.formAlert.description"
            :hide-details="true"
            color="orange"
            label="Descripción"
            outlined
            dense
            clearable
            rows="2"
            :error-messages="alertsStore.errors.description
              && alertsStore.errors.description[0]"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" class="pb-0">
          <div class="mr-3 text-caption"> <strong>Seleccion de equipo</strong> </div>
        </v-col>
        <v-col cols="12" md="12" class="pb-0">
          <v-autocomplete
            :disabled="alertsStore.formAlert.id ? true : false"
            color="orange"
            small-chips
            outlined
            chips
            multiple
            v-model="alertsStore.formAlert.selectedTrucks"
            label="Equipos"
            :item-text="(item) => item.code + ' - ' + item.patent"
            :item-value="(item) => item.id"
            :items="alertsStore.trucks"
            :error-messages="alertsStore.errors.selectedTrucks
              && alertsStore.errors.selectedTrucks[0]"
            clearable
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" class="pb-0">
          <div class="mr-3 text-caption"><strong>Plan</strong></div>

        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" md="2" class="pb-0">
          <v-checkbox
            color="orange"
            v-model="alertsStore.formAlert.expirationSettings.dateSelected"
            value
            class="mt-1"
          ></v-checkbox>
        </v-col>
        <v-col cols="10" md="10" class="pb-0">
          <v-text-field
            color="orange"
            clearable
            label="Fecha de mantención"
            v-model="alertsStore.formAlert.expirationSettings.date"
            :disabled="!alertsStore.formAlert.expirationSettings.dateSelected"
            outlined
            :hide-details="false"
            type="date"
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2" md="2" class="pb-0">
          <v-checkbox
            v-model="alertsStore.formAlert.expirationSettings.kilometersSelected"
            value
            color="orange"
            class="mt-1"
          ></v-checkbox>
        </v-col>
        <v-col cols="10" md="10" class="pb-0">
          <v-text-field
            v-model="alertsStore.formAlert.expirationSettings.kilometers"
            color="orange"
            :counter="10"
            label="Kilometraje mantención"
            required
            placeholder="10.000 km"
            type="number"
            outlined
            dense
            :disabled="!alertsStore.formAlert.expirationSettings.kilometersSelected"
            :error-messages="alertsStore.errors['expirationSettings.kilometers']
              && alertsStore.errors['expirationSettings.kilometers'][0]"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12" class="pb-0">
          <div class="mr-3 text-caption"><strong>Recordatorio</strong></div>
          <div class="text-caption">
            </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" md="2" class="pb-0">
          <v-checkbox
            input-value="true"
            value
            color="orange"
            v-model="alertsStore.formAlert.alertSettings.daysSelected"
            class="mt-1"
          ></v-checkbox>
        </v-col>
        <v-col cols="10" md="10" class="pb-0">
          <v-text-field
            :counter="1"
            v-model="alertsStore.formAlert.alertSettings.days"
            label="Dias de antelación"
            required
            outlined
            type="number"
            :hide-details="true"
            dense
            :disabled="!alertsStore.formAlert.alertSettings.daysSelected"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2" md="2" class="pb-0">
          <v-checkbox
            v-model="alertsStore.formAlert.alertSettings.kilometersSelected"
            value
            color="orange"
            class="mt-1"
          ></v-checkbox>
        </v-col>
        <v-col cols="10" md="10" class="pb-0">
          <v-text-field
            v-model="alertsStore.formAlert.alertSettings.kilometers_advance"
            :hide-details="true"
            color="orange"
            :counter="10"
            type="number"
            label="Kilómetros de antelación"
            placeholder="Ejemplo : 1000"
            required
            outlined
            dense
            :disabled="!alertsStore.formAlert.alertSettings.kilometersSelected"
          >
        </v-text-field>
        <span class="text-caption font-weight-light">
          <v-icon size="13">mdi-information-outline</v-icon>
          Kilometraje antes de la fecha de mantención</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12" class="pb-0">
          <div class="mr-3">Lista de correos</div>
          <div class="caption text-left">
            <b>Se enviará a todos los correos ingresados</b>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12" class="pb-0">
          <v-autocomplete
            chips
            small-chips
            v-model="alertsStore.formAlert.emails_to_notifications.selected"
            label="Correos"
            color="orange"
            :items="alertsStore.formAlert.emails_to_notifications.suggestions"
            hide-no-data
            hide-selected
            multiple
            dense
            outlined
            :error-messages="alertsStore.errors['emails_to_notifications.selected']
              && alertsStore.errors['emails_to_notifications.selected'][0]"
          >
        </v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="py-3">
        <v-col cols="12" md="12">
          <v-btn :loader="loader"
           v-if="!alertsStore.formAlert.id"
           block color="orange" elevation="0" style="color: white" @click="handleStoreAlert()"
            >Crear alertas</v-btn
          >
          <div v-if="alertsStore.formAlert.id">
            <v-btn
            :loader="loader"
            block color="orange" elevation="0" style="color: white" @click="handleUpdateAlert()"
            >Actualizar alerta</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { useAppStore } from '@/store/app';
import { useMaintenanceAlertsStore } from '@/maintenance-alerts/store/maintenance-alerts-store';

export default {
  data: () => ({
    store: useAppStore(),
    alertsStore: useMaintenanceAlertsStore(),
    search: '',
    loader: false,
  }),
  created(){
    this.handleFetchCateries()
    this.handleFetchEmails()

  },
  methods: {
    handleBack() {
      this.alertsStore.resetFormAlert();
      this.alertsStore.resetErrors();
      this.store.toogleGeneralDrawer(false,'')
    },
    async handleFetchCateries() {
      try {
        await this.alertsStore.getCategories();

      } catch (error) {
        this.store.snackbar = {
          visible: true,
          text: 'Error al cargar categorias ⛔️',
        };
      }
    },
    async handleFetchEmails(){
      try {
        await this.alertsStore.handleGetEmails();
      } catch (error) {
        this.store.snackbar = {
          visible: true,
          text: 'Error al cargar correos ⛔️',
        };
      }
    },
    async handleStoreAlert() {
      this.loader = true;
      try {
        await this.alertsStore.storeAlert();
        this.alertsStore.getAlerts();
        this.store.openSnackbar('Alerta creada correctamente ✅')
        this.store.toogleGeneralDrawer(false,'')
        this.alertsStore.resetFormAlert();
        this.alertsStore.resetErrors();
      } catch (error) {
        if (error.response.status === 422) {
          this.alertsStore.errors = error.response.data.errors;
        } else {
          this.store.snackbar = {
            visible: true,
            text: 'Error al crear alerta ⛔️',
          };
        }
      }

      this.loader = false;
    },
    async handleUpdateAlert() {
      this.loader = true;
      try {
        await this.alertsStore.updateAlert();
        this.alertsStore.getAlerts();
        this.store.openSnackbar('Alerta actualizada correctamente ✅')
        this.store.toogleGeneralDrawer(false,'')
        this.alertsStore.resetFormAlert();
        this.alertsStore.resetErrors();
      } catch (error) {
        if (error.response.status === 422) {
          this.alertsStore.errors = error.response.data.errors;
        } else {
          this.store.snackbar = {
            visible: true,
            text: 'Error al actualizar alerta ⛔️',
          };
        }
      }

      this.loader = false;
    },
    createCateroryMaintenanceAlert() {
      this.store.toogleGeneralDrawer(true, 'form-category-alert');
    },
  },
};
</script>
<style scoped>
.tag-name {
    background: #9f9f9f2b;
    padding: 7px;
    border-radius: 5px;
    width: 100%;
}
</style>
