var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-list',[_c('v-list-item',[_c('v-icon',{staticClass:"mr-3",on:{"click":function($event){return _vm.handleBack()}}},[_vm._v("mdi-arrow-left")]),_c('v-text-field',{attrs:{"disabled":_vm.selectAll,"color":"orange","label":"Buscar insumo","required":"","solo":"","hide-details":""},on:{"input":_vm.debouncedFetchSupplies},model:{value:(_vm.useWarehouseStore.params.search),callback:function ($$v) {_vm.$set(_vm.useWarehouseStore.params, "search", $$v)},expression:"useWarehouseStore.params.search"}}),_c('v-btn',{staticClass:"ml-3",attrs:{"disabled":_vm.useWarehouseStore.supplies_list_selectable.filter((el) => el.selectable).length <= 0,"loading":_vm.loader,"small":"","color":"orange","dark":"","elevation":"0"},on:{"click":function($event){return _vm.attachSupplies()}}},[_vm._v("Guardar")]),_vm._t("header")],2),_c('v-divider'),(_vm.useWarehouseStore.loader_attach_supplies_search)?_c('v-progress-linear',{attrs:{"color":"orange","indeterminate":"","reverse":""}}):_vm._e(),(_vm.useWarehouseStore.supplies_list_selectable.length != 0)?_c('div',[_c('v-list-item',{attrs:{"disabled":_vm.useWarehouseStore.loader_attach_supplies_search}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"disabled":_vm.useWarehouseStore.loader_attach_supplies_search,"color":"orange"},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1),_c('v-list-item-title',{staticClass:"text-caption"},[_vm._v("Seleccionar todo")])],1)],1):_vm._e(),(_vm.selectAll)?_c('div',{staticClass:"text-caption mx-5"},[_vm._v(" "+_vm._s(_vm.useWarehouseStore.totalSuppliesAvailables)+" insumo(s) seleccionado(s) ")]):_vm._e(),(!_vm.selectAll &&
      _vm.useWarehouseStore.stock_supply_selected.filter(el => el.selectable).length >= 1)?_c('div',{staticClass:"text-caption mx-5"},[_vm._v(" "+_vm._s(_vm.useWarehouseStore.stock_supply_selected. filter(el => el.selectable).length)+" insumo(s) seleccionado(s) ")]):_vm._e(),_c('div',[(
          _vm.useWarehouseStore.loader_attach_supplies &&
          !_vm.useWarehouseStore.loader_attach_supplies_search
        )?_c('div',{staticClass:"text-caption text-center mt-5"},[_vm._v(" Cargando insumos, espere un momento porfavor...⏳ ")]):_vm._e(),(
          !_vm.useWarehouseStore.loader_attach_supplies &&
          _vm.useWarehouseStore.supplies_list_selectable.length === 0
        )?_c('div',{staticClass:"text-caption text-center mt-5"},[_vm._v(" No se encuentran insumos disponibles ")]):_vm._e(),_vm._l((_vm.useWarehouseStore.supplies_list_selectable),function(item,index){return _c('v-list-item',{key:index,attrs:{"disabled":_vm.selectAll,"selectable":"","multiple":"","value":item}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"disabled":_vm.selectAll,"color":"orange"},on:{"change":function($event){return _vm.selectionChanged(item)}},model:{value:(item.selectable),callback:function ($$v) {_vm.$set(item, "selectable", $$v)},expression:"item.selectable"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{attrs:{"disabled":_vm.useWarehouseStore.loader_attach_supplies_search}},[_vm._v("mdi-package-variant")]),_vm._v(" "+_vm._s(item.name))],1),_c('div',[_c('div',{staticClass:"w-50 d-flex justify-space-between caption mt-2"},[_c('div',[_vm._v("Tipo")]),_c('div',[_vm._v(_vm._s(item.category ? item.category.name : 'SIN CATEGORIA'))])]),_c('div',{staticClass:"w-50 d-flex justify-space-between caption"},[_c('div',[_vm._v("Código")]),_c('div',[_vm._v(_vm._s(item.id))])]),_c('div',{staticClass:"w-50 d-flex justify-space-between caption"},[_c('div',[_vm._v("Precio unitario")]),_c('div',[_c('strong',[_vm._v(" $"+_vm._s(item.cost))])])]),_c('div',{staticClass:"w-50 d-flex justify-space-between caption"},[_c('div',[_vm._v("Ubicación")]),_c('div',[_c('strong',[_vm._v(" "+_vm._s(item.workshop.name))])])])])],1)],1)}),_c('div',{staticStyle:{"padding":"1em"}},[(_vm.useWarehouseStore.last_page != 1)?_c('v-btn',{attrs:{"disabled":_vm.selectAll,"color":"orange","dark":"","outlined":"","small":"","block":"","loading":_vm.useWarehouseStore.loader_attach_supplies},on:{"click":function($event){return _vm.loadMoreSupplies()}}},[_vm._v(" Cargar más ")]):_vm._e()],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }