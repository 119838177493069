/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
export const applyDrag = (arr, dragResult) => {
  const {
    removedIndex,
    addedIndex,
    payload,
  } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];

  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

export const generateItems = (count, creator) => {
  const result = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < count; i++) {
    result.push(creator(i));
  }
  return result;
};

export const compareObject = (obj1, obj2) => {
  // Verifica si los tipos de obj1 y obj2 son diferentes
  if (typeof obj1 !== typeof obj2) {
    return true;
  }

  // Verifica si obj1 y obj2 son objetos
  if (typeof obj1 === 'object' && obj1 !== null && typeof obj2 === 'object' && obj2 !== null) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Verifica si las claves (propiedades) de los objetos son diferentes
    if (keys1.length !== keys2.length) {
      return true;
    }

    // Compara los valores de las propiedades de los objetos recursivamente
    for (const key of keys1) {
      if (compareObject(obj1[key], obj2[key])) {
        return true;
      }
    }

    // Las estructuras y los valores son iguales
    return false;
  }

  // Compara los valores simples (no objetos) de obj1 y obj2
  return obj1 !== obj2;
};

export const customFilter = (item, queryText) => {
  const searchTerms = queryText.toLowerCase().split(' ');
  return searchTerms.every((term) => Object.values(item).some((v) => v && v.toString()
    .toLowerCase().includes(term)));
};
