/* eslint-disable */
// src/stores/warehouseStore.js
import api from '@/warehouse/services/WarehouseService';

import {
  defineStore
} from 'pinia';

export const useWarehouseStore = defineStore('warehouseStore', {
  state: () => ({
    transactionType: null,
    warehouses: [],
    supplies_list_selectable: [],
    last_page : 0,
    stock_supply_selected: [],
    supplies_available: [],
    loader_attach_supplies: false,
    loader_attach_supplies_search: false,
    params: {
      page: 1,
      perPage: 5,
      search: ''
    },
    stock: [],
    total_stock : 0,
    warehouse: {
      code: "",
      name: "",
      description: "",
      direction: "",
      workshop_id: ""
    },
    priorityOptions: [{
      value: 'high',
      text: 'Alta'
    }, {
      value: 'medium',
      text: 'Media'
    }, {
      value: 'low',
      text: 'Baja'
    }],
    form_purchase_order: {
      provider_id: '',
      status: '',
      document: '',
      countSupplies: '',
      createdAt: '',
      deliveryAt: '',
      reference: '',
      note: '',
      total: '',
      tax: '',
      subtotal: '',
      supplies: [],
    },
    form_request_supply: {
      supply: null,
      unit: null,
      unit_request: null,
      unit_cost: null,
      total: null,
      tax: null,
    },
    errors: {},
    providers: [],
    selectedSupplies : [],
    totalSuppliesAvailables : 0,
    totalSuppliesSelected : 0,
    loadingFetchStock : false
  }),

  actions: {
    supplySelected(item) {
      this.stock_supply_selected.push(item);
    },
    async fetchWarehouse(warehouseId) {
      const response = await api.getWarehouse(warehouseId);
      this.warehouse = response.data;
    },
    async fetchStock(warehouseId, params) {
      this.loadingFetchStock = true;
      const response = await api.getStock(warehouseId, params);
      this.stock = response.data.data;
      this.total_stock = response.data.total
      this.loadingFetchStock = false;
    },

    async fetchProviders() {
      const response = await api.getProviders();
      this.providers = response.data;
    },

    async fetchSuppliesAvailable(warehouseId) {
      const response = await api.getInStock(warehouseId,this.params);
      this.supplies_available = response.data.data;
    },

    getSelectedSupplies() {
      return this.supplies_list_selectable.filter(supply => supply.selectable);
    },

    async fetchSuppliesListSelectable(warehouseId) {
      this.loader_attach_supplies = true;
      if (this.params.search != '') {
        this.loader_attach_supplies_search = true;
      }
      const response = await api.getSuppliesSelectable(warehouseId, this.params);
       
      this.supplies_list_selectable = response.data.data.map(el => ({
        ...el,
        selectable: this.stock_supply_selected.some(selected => selected.id === el.id)
      }));
      

      this.last_page = response.data.last_page;

      if (this.params.search != '') {
        this.loader_attach_supplies_search = false;
      }
      this.loader_attach_supplies = false;

      this.totalSuppliesAvailables = response.data.total;
    },

    async attachSupplyToWarehouse(warehouseId, supplies, selectedAll) {
      const response = await api.attachSupplyToWarehouse(warehouseId, supplies, selectedAll);
    },
    async fetchWarehouses() {
      const response = await api.getWarehouses();
      this.warehouses = response.data;
    },
    async storeWarehouse(warehouse) {
      const response = await api.createWarehouse(warehouse);
      this.warehouse = response.data;
    },

    async addWarehouse(warehouse) {
      const response = await api.createWarehouse(warehouse);
      this.warehouses.push(response.data);
    },

    async updateWarehouse(warehouseId, warehouse) {
      const response = await api.updateWarehouse(warehouseId, warehouse);
      this.warehouse = {
        ...response.data
      };
    },

    async deleteWarehouse(warehouseId) {
      await api.deleteWarehouse(warehouseId);
      this.warehouses = this.warehouses.filter(wh => wh.id !== warehouseId);
    },


    // Mantenier supply purchase order
    addSupply(supplyData) {
      this.form_purchase_order.supplies.push(supplyData);

      this.form_request_supply = {
        supply: null,
        unit: null,
        unit_request: null,
        unit_cost: null,
        total: null,
        tax: null,
      };
    },
    readSupply(supplyId) {
      this.form_request_supply = this.form_purchase_order.supplies.find(supply => supply.supply
        .id ===
        supplyId);
    },

    updateSupply(updatedSupply) {
      const index = this.form_purchase_order.supplies.findIndex(supply => supply.id ===
        updatedSupply.id);
      if (index !== -1) {
        this.form_purchase_order.supplies[index] = updatedSupply;
      }
    },

    deleteSupply(supplyId) {
      const index = this.form_purchase_order.supplies.findIndex(supply => supply.supply.id ===
        supplyId);

      this.form_purchase_order.supplies.splice(index, 1)
    },
    calculateTotals() {
      const subtotal = this.form_purchase_order.supplies.reduce((acc, supply) => {
        return acc + (supply.unit_cost * supply.unit_request);
      }, 0);
      const tax = subtotal * 0.19;
      const total = subtotal + tax;
      return {
        subtotal,
        tax,
        total,
      };
    },
    resetFormRequestSupply() {
      this.form_request_supply = {
        supply: null,
        unit: null,
        unit_request: null,
        unit_cost: null,
        total: null,
        tax: null,
      };
    },
    resetWarehouse() {
      this.warehouse = {
        code: "",
        name: "",
        description: "",
        direction: "",
        workshop_id: ""
      };

    },
  },
});
