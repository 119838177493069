// settingService.js

import axios from '@/plugins/axios';

// Reemplaza con la URL de tu API

const SettingService = {
  async getSettings() {
    try {
      const response = await axios.get('v1/settings');
      return response.data;
    } catch (error) {
      throw new Error('Error al obtener las configuraciones.');
    }
  },
  async getPreferences() {
    try {
      const response = await axios.get('v1/preferences');
      return response.data;
    } catch (error) {
      throw new Error('Error al obtener las preferencias.');
    }
  },
  async getPreferencesByUser(userId) {
    try {
      const response = await axios.get(`v1/preferences/${userId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error al obtener las preferencias.');
    }
  },
};

export default SettingService;
