/* eslint-disable */
// src/stores/warehouseStore.js
import apiTransaction from '@/warehouse/services/TransactIonService';
import apiWarehouse from '@/warehouse/services/WarehouseService';

import {
  defineStore
} from 'pinia';

export const useTransaction = defineStore('useTransaction', {
  state: () => ({
    loaderTransaction: false,
    transactionType: null,
    transactions: [],
    form_transaction: {
      transaction: null,
      date: null,
      document: null,
      typeTrasactionId: null,
      providerId: null,
      reference: null,
      note: null,
      supplies: [],
      typeInvoice: null,
      date_delivery : null,
      payment_type : null
    },
    totalTransactions : 0,
    typeTrasactionsEntry: [],
    typeTrasactionsSales: [],
    providers: [],
    errors: {},
  }),

  actions: {
    async fetchVerifyStock(supplyId, warehouseId, oa) {
      const response = await apiTransaction.verifyStock(supplyId, warehouseId, quantityParam);
    },
    async fetchTypesTrasactions(type) {
      const response = await apiTransaction.getTypeTransaction(type);
      this.typeTrasactionsEntry = response.data;
      this.typeTrasactionsSales = response.data;
    },
    async fetchTrasactions(type, warehouse, params) {
      this.loaderTransaction = true;
      const response = await apiTransaction.getTransactions(type, warehouse, params);
      this.transactions = response.data.data;
      this.loaderTransaction = false;
    },
    async fetchProviders() {
      const response = await apiWarehouse.getProviders();
      this.providers = response.data;
    },
    async storeTransaction(warehouse, transaction) {
      this.form_transaction.date_delivery = transaction.date;
      const response = await apiTransaction.createTransaction(warehouse, transaction);
    },
    resetTransaction() {
      this.form_transaction = {
        transaction: null,
        date: null,
        document: null,
        typeTrasactionId: null,
        providerId: null,
        reference: null,
        note: null,
        typeInvoice: null,
        supplies: [],
      };
    },
  },
});
