<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent :max-width="width">
      <v-card>
        <v-card-title class="text-h5">
          {{ title }}
        </v-card-title>
        <v-card-text>{{ message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <slot name="actions">
            <v-btn color=" darken-1" text @click="cancel"> {{ button_cancel }} </v-btn>
            <v-btn color="orange" text @click="agree">
              {{ button_agree }}
            </v-btn>
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    button_agree: null,
    button_cancel: null,
    width: 400,
  }),
  methods: {
    open(title, message, buttonCancel, buttonAgree, width = 400) {
      this.dialog = true;
      this.button_cancel = buttonCancel;
      this.button_agree = buttonAgree;
      this.title = title;
      this.message = message;
      this.width = width;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
