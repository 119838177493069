<template>
  <div class="mx-3 my-3">
    <!-- Insumo (Autocomplete) -->
    <div style="width: 100%" class="d-flex justify-space-between">
      <div><v-icon class="mr-3" @click="back()">mdi-arrow-left</v-icon> Agregar insumo</div>
      <div>
        <v-btn v-if="!useOrderPurchase.isEdited"
         :disabled="!isValidForm()" @click="addSupply()" class="ml-3" small
          color="orange" dark elevation="0">Guardar</v-btn>
        <v-btn v-if="useOrderPurchase.isEdited"
        @click="handleUpdateSupply()" class="ml-3" small color="orange" dark
          elevation="0">Actualizar</v-btn>
      </div>
    </div>
    <br />
    <v-autocomplete v-model="useOrderPurchase.form_request_supply.supply"
    :items="supplies_available"
      label="Buscador insumos" item-text="name"
      return-object outlined dense :disabled="useOrderPurchase.isEdited"
      @click:clear="handleClearable('search')"
       :search-input.sync="search" @change="onChangeSupply" cache-items
       clereable no-data-text="No se encontraron insumos">
      <template slot="item" slot-scope="{ item }">
        <v-list-item-content>
          <v-list-item-title><v-icon>mdi-package-variant</v-icon>
            {{ item.name }}</v-list-item-title>
          <div>
            <div class="w-50 d-flex justify-space-between caption mt-2">
              <div>ID</div>
              <div>{{ item.id  }}</div>
            </div>
            <div class="w-50 d-flex justify-space-between caption">
              <div>Código cliente</div>
              <div>{{ item.code ? item.code : 'SIN CÓDIGO' }}</div>
            </div>
            <div class="w-50 d-flex justify-space-between caption">
              <div>Tipo</div>
              <div>{{ item.category ? item.category.name : 'SIN CATEGORIA' }}</div>
            </div>
            <div class="w-50 d-flex justify-space-between caption">
              <div>Precio unitario</div>
              <div>
                <strong> {{ formatCLP(item.cost) }}</strong>
              </div>
            </div>
            <div class="w-50 d-flex justify-space-between caption">
              <div>Ubicación</div>
              <div>
                <strong> {{ item.workshop.name }}</strong>
              </div>
            </div>
          </div>
        </v-list-item-content>
      </template>
    </v-autocomplete>

    <!-- Unidad (Autocomplete) -->
    <v-autocomplete disabled v-model="useOrderPurchase.form_request_supply.unit"
      :items="useInvertoryStore.measurement_units"
       label="Unidad de medida" item-text="name" item-value="id" outlined
      dense>
    </v-autocomplete>

    <!-- Unidad Pedida -->
    <v-text-field v-model="useOrderPurchase.form_request_supply.unitRequest"
     label="Cantidad" outlined
      @change="changeUnitRequest" dense number :rules="[validateNonNegativeNumber]"
      :error-messages="hasStock ? 'No hay stock suficiente' : ''"></v-text-field>

    <!-- Costo Unitario -->
    <v-text-field v-model="useOrderPurchase.form_request_supply.unitCost"
    label="Costo Unitario" outlined dense
      @change="changeUnitCost">
      <template v-slot:prepend-inner>
        <v-icon size="20" color="grey">mdi-currency-usd</v-icon>
      </template>
    </v-text-field>

    <!-- Impuesto (Select) -->
    <v-select v-model="useOrderPurchase.form_request_supply.taxObject"
    :items="taxItems" label="Impuesto" item-text="item"
      item-value="value" outlined dense @change="changeTax">
      <template v-slot:prepend-inner>
        <v-icon size="20" color="|grey">mdi-percent-outline</v-icon>
      </template></v-select>
    <v-text-field v-model="useOrderPurchase.form_request_supply.neto"
     disabled label="Costo neto" outlined dense>
      <template v-slot:prepend-inner>
        <v-icon size="20" color="grey">mdi-currency-usd</v-icon>
      </template></v-text-field>
    <!-- Costo Total -->
    <v-text-field v-model="useOrderPurchase.form_request_supply.total"
     disabled label="Costo total" outlined dense>
      <template v-slot:prepend-inner>
        <v-icon size="20" color="grey">mdi-currency-usd</v-icon>
      </template></v-text-field>
  </div>
</template>

<script>
import formatCLP from '@/helpers/money';
import { useAppStore } from '@/store/app';
import { useInvertoryStore } from '@/inventory/store/inventory';
import { customFilter } from '@/worker_order/services/helpers';
import WarehouseService from '@/warehouse/services/WarehouseService';
import { useWarehouseStore } from '../store/warehouse';
import { useOrderPurchase } from '../store/orderPurchase';
import { useTransaction } from '../store/transaction';

export default {
  data() {
    return {
      store: useAppStore(),
      useWarehouseStore: useWarehouseStore(),
      useOrderPurchase: useOrderPurchase(),
      useInvertoryStore: useInvertoryStore(),
      useTransaction: useTransaction(),
      hasStock: false,
      taxItems: [
        { item: '19%', value: 1.19, otherValue: 0.19 },
        { item: '21%', value: 1.21, otherValue: 0.21 },
        { item: 'Exento', value: 1.0, otherValue: '0' },
      ],
      supplies_available: [],
      search: '',
    };
  },
  watch: {
    'useOrderPurchase.form_request_supply.unitRequest': {
      handler(val) {
        if (val) {
          this.validateNonNegativeNumber(val);
        }
      },
      deep: true,
    },
    search(val) {
      // eslint-disable-next-line no-unused-expressions
      this.useWarehouseStore.params.search = val;
      this.querySelections();
    },
  },
  methods: {
    querySelections() {
      this.loading = true;
      this.fetchSuppliesAvailable();
    },
    searchFilter() {
      this.fetchSuppliesAvailable(this.$route.params.id);
    },
    async fetchSuppliesAvailable() {
      const warehouse = this.$route.params.id;
      const response = await WarehouseService.getInStock(warehouse, this.useWarehouseStore.params);
      console.log(response.data);
      this.supplies_available = response.data.data;
    },
    customFilter,
    validateQuantity() {
      this.$refs.form.validate();
    },
    validateNonNegativeNumber(value) {
      if (value > 0) {
        return true; // Es un número válido (no negativo)
      }
      return 'La cantidad debe ser un número no negativo o mayor a 0';
    },
    changeTax(val) {
      this.useOrderPurchase.form_request_supply.taxObject = this.taxItems.find(
        (item) => item.value === val,
      );
      const { unitCost, unitRequest } = this.useOrderPurchase.form_request_supply;
      this.useOrderPurchase.form_request_supply.total = unitRequest * unitCost * val;
    },
    changeUnitCost(val) {
      const { unitCost, unitRequest } = this.useOrderPurchase.form_request_supply;

      this.useOrderPurchase.form_request_supply.neto = val;

      if (this.useOrderPurchase.form_request_supply.taxObject.value) {
        this.useOrderPurchase.form_request_supply.taxObject = this.taxItems.find(
          (item) => item.value === this.useOrderPurchase.form_request_supply.taxObject.value,
        );
      }

      if (this.useOrderPurchase.form_request_supply.taxObject) {
        this.useOrderPurchase.form_request_supply.total = unitRequest * unitCost
          * this.useOrderPurchase.form_request_supply.taxObject.value;
      }
    },
    async changeUnitRequest(val) {
      const { unitCost, unitRequest } = this.useOrderPurchase.form_request_supply;
      try {
        if (this.useWarehouseStore.transactionType === 'exit') {
          await this.useTransaction.fetchVerifyStock(
            this.useOrderPurchase.form_request_supply.supply.id,
            this.$route.params.id,
            unitRequest,
          );

          this.hasStock = false;
        }
      } catch (error) {
        this.hasStock = true;
      }
      const total = parseFloat(val) * parseFloat(unitCost);

      this.useOrderPurchase.form_request_supply.neto = total;

      if (this.useOrderPurchase.form_request_supply.taxObject.value) {
        this.useOrderPurchase.form_request_supply.taxObject = this.taxItems.find(
          (item) => item.value === this.useOrderPurchase.form_request_supply.taxObject.value,
        );
      }

      if (this.useOrderPurchase.form_request_supply.taxObject) {
        this.useOrderPurchase.form_request_supply.total = unitRequest * unitCost
          * this.useOrderPurchase.form_request_supply.taxObject.value;
      }
    },
    formatCLP,
    handleUpdateSupply() {
      this.useOrderPurchase.updateSupply(this.useOrderPurchase.form_request_supply.supply.id, {
        ...this.useOrderPurchase.form_request_supply,
      });
      const { total, subtotal, tax } = this.useOrderPurchase.calculateTotals();
      this.useOrderPurchase.form_purchase_order.total = total;
      this.useOrderPurchase.form_purchase_order.subtotal = subtotal;
      this.useOrderPurchase.form_purchase_order.tax = tax;
      this.useOrderPurchase.isEdited = false;
      this.store.toogleGeneralDrawer(false, this.store.currentFormDialog);
      this.useOrderPurchase.resetFormSupply();
      this.store.openSnackbar('insumo actualizado correctamente  ✅');
    },
    addSupply() {
      const { supply } = this.useOrderPurchase.form_request_supply;
      const index = this.useOrderPurchase.form_purchase_order.supplies.findIndex(
        (item) => item.supply.id === supply.id,
      );
      if (index >= 0) {
        this.store.openSnackbar('El insumo ya se encuentra en la lista');
      } else {
        this.useOrderPurchase.addSupply(this.useOrderPurchase.form_request_supply);
        this.store.toogleGeneralDrawer(false, this.store.currentFormDialog);
        this.store.openSnackbar('Insumo agregado correctamente  ✅');
        const { total, subtotal, tax } = this.useOrderPurchase.calculateTotals();
        this.useOrderPurchase.form_purchase_order.total = total;
        this.useOrderPurchase.form_purchase_order.subtotal = subtotal;
        this.useOrderPurchase.form_purchase_order.tax = tax;
      }
    },
    back() {
      this.hasStock = false;
      this.store.toogleGeneralDrawer(false, this.store.currentFormDialog);
      this.useOrderPurchase.isEdited = false;
      this.useOrderPurchase.form_request_supply = {
        supply: null,
        unit: null,
        unitRequest: null,
        unitCost: null,
        total: null,
        tax: null,
        neto: null,
        taxObject: {
          value: 1.19,
          item: '19%',
        },
      };
    },
    onChangeSupply(val) {
      this.useOrderPurchase.form_request_supply.unitRequest = null;
      this.useOrderPurchase.form_request_supply.unitCost = val.cost;
      this.useOrderPurchase.form_request_supply.unit = val.measurement_units_id;
    },
    isValidForm() {
      return (
        !this.hasStock
        && this.useOrderPurchase.form_request_supply.supply != null
        && this.useOrderPurchase.form_request_supply.unitRequest != null
        && this.useOrderPurchase.form_request_supply.unitRequest > 0
        && this.useOrderPurchase.form_request_supply.unitCost != null
        && this.useOrderPurchase.form_request_supply.total != null
        && this.useOrderPurchase.form_request_supply.taxObject != null
      );
    },
  },
};
</script>
