/* eslint-disable */
import {
  defineStore,
} from 'pinia';
import apiInventory from '@/inventory/services/InventoryService';

export const useInvertoryStore = defineStore('inventory', {
  state: () => ({
    channel :'',
    importFailed: false,
    uploaded : false,
    loader_upload : false, 
    complete_upload : false,
    supplies : [],
    locations: [],
    categories: [],
    errors_form_supplies: {},
    measurement_units: [],
    counterFilter : 0,
    formSupplies: {
      workshop_id: '',
      code: '',
      name: '',
      description: '',
      cost: '',
      category_id: '',
      measurement_units_id: '',
    },
    defaultFormSupplies: {
      workshop_id: '',
      code: '',
      name: '',
      description: '',
      cost: '',
      category_id: '',
      measurement_units_id: '',
    },
    default_filter_supplies: {
      category_id: '',
      location: '',
      selectedCategories : []
    },
    filter_supplies: {
      search : '',
      search_loading : false,
      category_id: '',
      location: '',
      selectedCategories : []
    },
    totalSupplies: 0,
  }),
  actions: {
    async fetchMeasurementUnits(workshopId) { 
      const response = await apiInventory.getMeasurementUnits(workshopId);
      this.measurement_units = response.data;
    },
    async handleFetchSupplies() {
      this.filter_supplies.search_loading = true;
      try {
        const response = await apiInventory.getSupplies(this.filter_supplies);

        this.supplies = response.data.data.data;

        this.totalSupplies = response.data.data.total

      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
      this.filter_supplies.search_loading = false;
      // handle the response data here
    },
    resetFormSupplies() {
      this.formSupplies = {
        workshop_id: '',
        code: '',
        name: '',
        description: '',
        cost: '',
        category_id: '',
        measurement_units_id: '',
      };
    }
  },
});
