<template>
  <v-navigation-drawer
    v-model="store.generalDrawer"
    absolute
    temporary
    :permanent="store.generalDrawer"
    fixed
    :width="drawerWidth"
    right
  >
    <v-container style="padding: 0">
      <slot name="header"></slot>
    </v-container>

    <v-container style="padding: 0">
      <slot name="container"></slot>
    </v-container>
  </v-navigation-drawer>
</template>
<script>
import { useAppStore } from '@/store/app';

export default {
  data: () => ({
    store: useAppStore(),
  }),
  props: {
    drawerWidth: {
      type: [Number, String],
      default: 400,
    },
  },
  methods: {},
};
</script>
