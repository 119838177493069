<template>
  <div>

    <v-container fluid>
      <div>
      <v-btn icon @click="appStore.toogleGeneralDrawer(true, 'form-alert')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      Nueva categoría de alerta
      </div>
      <br>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="12">
            <v-text-field v-model="categoryAlert.name"
            label="Nombre" required outlined dense
            :error-messages="errors.name && errors.name[0]"></v-text-field>

            <v-btn block color="orange" elevation="0" dark
            @click="handleCreateCategoryAlert()">Guardar</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { useAppStore } from '@/store/app';
import { useMaintenanceAlertsStore } from '@/maintenance-alerts/store/maintenance-alerts-store';

export default {
  data() {
    return {
      appStore: useAppStore(),
      useMaintenanceAlertsStore: useMaintenanceAlertsStore(),
      valid: true,
      categoryAlert: {
        name: '',
        workshop_id: '',
        company_id: '',
      },
      errors: {},
    };
  },
  methods: {
    async handleCreateCategoryAlert() {
      try {
        this.categoryAlert.workshop_id = JSON.parse(localStorage.getItem('workshop')).id;
        this.categoryAlert.company_id = JSON.parse(localStorage.getItem('company')).id;
        await this.useMaintenanceAlertsStore.createCategoryAlert(this.categoryAlert);
        this.appStore.toogleGeneralDrawer(true, 'form-alert');
        this.appStore.openSnackbar('Categoría creada correctamente ✅');
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
