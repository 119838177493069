<template>
  <v-container fluid>
    <div>
      <v-btn icon @click="useAppStore.toogleGeneralDrawer(false, ''); resetService()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      Nuevo tipo
      </div>
      <br>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-form ref="form" >
          <v-text-field
            v-model="useTruckStore.typeOfTruck.name"
            label="Nombre"
            required
            outlined
            dense
            :error-messages="errors.name && errors.name[0]"
          ></v-text-field>

          <v-btn v-if="!useTruckStore.typeOfTruck.id" color="orange" dark block
          :loading="loading"
          elevation="0" @click="handleCreateType">Guardar</v-btn>
          <v-btn v-if="useTruckStore.typeOfTruck.id" color="orange" dark block
          :loading="loading"
          elevation="0" @click="handleUpdateType">Actualizar</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useAppStore } from '@/store/app';
import { useTruckStore } from '../store/truck';

export default {
  props: {
    showToogle: Boolean,
    showCreateCategory: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      useTruckStore: useTruckStore(),
      useAppStore: useAppStore(),
      valid: true,
      loading: false,
      errors: {},
    };
  },
  created() {
  },
  methods: {
    async handleCreateType() {
      this.loading = true;
      this.errors = {};
      this.useTruckStore.coupled.company_id = this.useAppStore.company.id;
      this.useTruckStore.coupled.workshop_id = this.useAppStore.workshop.id;
      try {
        await this.useTruckStore.storeTypeOfTruck(this.useTruckStore.typeOfTruck);
        this.useAppStore.toogleGeneralDrawer(false, '');
        this.useAppStore.openSnackbar('Tipo creado correctamente ✅', 'success');
        this.useTruckStore.fetchTypesOfTruck();
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
        console.log(error);
        this.useAppStore.openSnackbar('⛔️ Error al crear el Tipo', 'error');
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async handleUpdateType() {
      this.loading = true;
      this.errors = {};
      try {
        await this.useTruckStore.updateCoupled(this.coupled);
        this.useAppStore.toogleGeneralDrawer(false, '');
        this.useAppStore.openSnackbar('Acoplado actualizado correctamente ✅', 'success');
      } catch (error) {
        this.errors = error.response.data.errors;
        this.useAppStore.openSnackbar('Error al actualizar el acoplado', 'error');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
