/* eslint-disable */
// src/stores/providerStore.js
import api from '@/provider/services/ProviderService';
import apiInvoice from '@/provider/services/InvoiceService';

import {
  defineStore
} from 'pinia';

export const useProviderStore = defineStore('providerStore', {
  state: () => ({
    providers: [],
    provider: {
      id: "",
      name: "",
      dni: "",
      phone: "",
      email: "",
      address: "",
      workshop_id: ""
    },
    errors: {},
    service: {
      id: '',
      code: '',
      name: '',
      description: '',
      unit_price: '',
      provider_id: '',
      workshop_id: '',
      category_id: '',
      company_id: '',
    },
    services:[],
    categories: [],
    params : {
      page: 1,
      perPage: 10,
      search: '',
      sortBy: 'name',
      sortDesc: false,
      ProviderId : ''
    },
    totalServices : 0,
    providersByWorkshop: [],
    servicesByProvider: [],
    selectedProvider: null,
    invoice: {
      id : null,
      bill_invoice: null,
      date: null,
      document: null,
      payment_type: null,
      note: null,
      company_id: null,
      workshop_id: null,
      work_order_id: null,
      provider_id: null,
    },
    bills: [],
    errorsInvoice: {},
  }),

  actions: {
    async fetchProviderByWorkshopId(workshopId) {
      const response = await api.getProviderByWorkshopId(workshopId);
      this.providersByWorkshop = response.data;
    },
    async fetchServiceByProvider(providerId) {
      const response = await api.getServiceByProvider(providerId, this.params);
      this.servicesByProvider = response.data.data;
      this.totalServices = response.data.total;

    },
    async fetchProviders() {
      const response = await api.getProviders();
      this.providers = response.data;
    },
    async fetchProvider(providerId) {
      const response = await api.getProvider(providerId);
      this.provider = response.data.data;
    },
    async fetchCategoriesService() {
      const response = await api.getCategoriesService();
      this.categories = response.data;
    },
    async createCategoryService(category) {
      const response = await api.createCategoryService(category);
    },
    async createServiceProvider(provider) {
      const response = await api.createServiceProvider(provider);
      this.provider = response.data;
    },
    async updateServiceProvider(providerId, provider) {
      const response = await api.updateServiceProvider(providerId, provider);
      this.provider = response.data
    },
    async fetchServiceProviderWorkOrder(){
      const response = await api.getAllServiceProvider();
      this.providers = response.data;
    },
    async fetchServiceProviders(providerId) {
      const response = await api.getServiceProviders(providerId);
      this.services = response.data;
    },
    async deleteServiceProvider(serviceId) {
      await api.deleteServiceProvider(serviceId);
      this.services = this.services.filter(wh => wh.id !== serviceId);
    },
    async storeProvider(provider) {
      const response = await api.createProvider(provider);
      this.provider = response.data;
    },

    async updateProvider(providerId, provider) {
      const response = await api.updateProvider(providerId, provider);
      this.provider = {
        ...response.data
      };
    },
    async deleteProvider(providerId) {
      await api.deleteProvider(providerId);
      this.providers = this.providers.filter(wh => wh.id !== providerId);
    },

    async storeInvoice(invoice) {
      const response = await apiInvoice.createInvoce(invoice);
      this.invoice = response.data;
    },
    async updateInvoice(invoiceId, invoice) {
      const response = await apiInvoice.updateInvoice(invoiceId, invoice);
      this.invoice = response.data;
    },
    async fetchInvoicesWorkOrder(workOrderId) {
      const response = await apiInvoice.getInvoicesWorkOrder(workOrderId);
      this.bills = response.data;
    },

    async deleteServiceOrderWork(serviceId){
      await api.deleteServiceOrderWork(serviceId);
      this.services = this.services.filter(wh => wh.id !== serviceId);
    },

    resetProvider() {
      this.provider = {
        id: "",
        name: "",
        dni: "",
        phone: "",
        email: "",
        address: "",
        workshop_id: ""
      };

    },
    resetInvoice() {
      this.invoice = {
        id : null,
        bill_invoice: null,
        date: null,
        document: null,
        payment_type: null,
        note: null,
        company_id: null,
        workshop_id: null,
        work_order_id: null,
        provider_id: null,
      };
    },
    resetDrawerService() {
      this.selectedProvider = null;
      this.params.search = '';
      this.servicesByProvider = [];
      this.totalServices = 0;
    }
  },
});
