// src/services/api.js
import axios from '@/plugins/axios';

export default {
  getProviders() {
    return axios.get('/v1/providers');
  },
  getProvider(providerId) {
    return axios.get(`/v1/providers/${providerId}`);
  },
  createProvider(provider) {
    return axios.post('/v1/providers', provider);
  },

  updateProvider(providerId, updatedProvider) {
    return axios.put(`/v1/providers/${providerId}`, updatedProvider);
  },

  deleteProvider(providerId) {
    return axios.delete(`/v1/providers/${providerId}`);
  },
  createServiceProvider(serviceParam) {
    return axios.post('/v1/provider/services/create', serviceParam);
  },
  updateServiceProvider(serviceId, serviceParam) {
    return axios.put(`/v1/provider/services/${serviceId}`, serviceParam);
  },
  getServiceProviders(providerId) {
    return axios.get(`/v1/provider/services/${providerId}`);
  },
  deleteServiceProvider(serviceId) {
    return axios.delete(`/v1/provider/services/${serviceId}`);
  },
  deleteServiceOrderWork(serviceId) {
    return axios.delete(`/v1/work-order/service/${serviceId}`);
  },
  createCategoryService(category) {
    return axios.post('/v1/provider/services-categories', category);
  },
  getCategoriesService() {
    return axios.get('/v1/provider/services-categories');
  },
  getServiceByProvider(providerId, params) {
    const targetParams = {
      ...params,
    };

    const targetFilter = {};

    Object.keys(targetParams).forEach((key) => {
      if (targetParams[key] !== 'search') {
        targetFilter['filter[Search]'] = targetParams.search;
      }
      if (targetParams[key] !== 'page') {
        targetFilter.page = targetParams.page;
      }
      if (targetParams[key] !== 'perPage') {
        targetFilter.perPage = targetParams.perPage;
      }
    });
    return axios.get(`/v1/service-by-provider/${providerId}`, {
      params: targetFilter,
    });
  },
  getProviderByWorkshopId(workshopId) {
    return axios.get(`/v1/provider-by-workshop/${workshopId}`);
  },
};
