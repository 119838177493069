/* eslint-disable */

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueSignaturePad from 'vue-signature-pad';
import moment from 'moment';
import {
  createPinia,
  PiniaVuePlugin
} from 'pinia'

import vuetify from './plugins/vuetify'
import { AgGridVue } from 'ag-grid-vue';

const pinia = createPinia()

Vue.use(PiniaVuePlugin)
Vue.config.productionTip = false;
Vue.use(VueSignaturePad);
Vue.prototype.moment = moment;
Vue.component('AgGridVue', AgGridVue);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/serviceWorker.js').then(registration => {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, err => {
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}


export const eventBus = new Vue();

new Vue({
  pinia,
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
