var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"p-5",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex justify-space-between align-centerP my-2",attrs:{"elevation":"0"}},[_c('div',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.handleBack()}}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-chevron-left")])],1),_vm._v(" "+_vm._s(this.useWarehouseStore.transactionType === 'entry' ? 'Nueva entrada' : 'Nueva salida')+" ")],1),_c('v-btn',{attrs:{"loading":_vm.loader,"disabled":_vm.loader,"elevation":"0","small":"","dark":"","color":"orange","dense":""},on:{"click":function($event){return _vm.handleStoreTransaction()}}},[_vm._v("Guardar")])],1),_c('br'),(_vm.useTransaction.errors.supplies)?_c('v-alert',{attrs:{"outlined":"","type":"warning","prominent":"","border":"left","dense":"","small":""}},[_vm._v(" En necesario ingresar al menos un insumo para poder guardar la entrada. ")]):_vm._e(),_c('br'),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-select',{attrs:{"label":"Tipo de movimiento","items":_vm.useTransaction.typeTrasactionsEntry,"item-value":"id","item-text":"name","outlined":"","error-messages":_vm.useTransaction.errors.typeTrasactionId && _vm.useTransaction.errors.typeTrasactionId[0]},model:{value:(_vm.useTransaction.form_transaction.typeTrasactionId),callback:function ($$v) {_vm.$set(_vm.useTransaction.form_transaction, "typeTrasactionId", $$v)},expression:"useTransaction.form_transaction.typeTrasactionId"}})],1),(this.useWarehouseStore.transactionType === 'entry')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"label":"Boleta/Factura","items":[
          { id: 'invoice', name: 'Boleta' },
          { id: 'bill', name: 'Factura' },
        ],"item-value":"id","item-text":"name","outlined":"","error-messages":_vm.useTransaction.errors.typeInvoice && _vm.useTransaction.errors.typeInvoice[0]},model:{value:(_vm.useTransaction.form_transaction.typeInvoice),callback:function ($$v) {_vm.$set(_vm.useTransaction.form_transaction, "typeInvoice", $$v)},expression:"useTransaction.form_transaction.typeInvoice"}})],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Documento","outlined":"","error-messages":_vm.useTransaction.errors.document && _vm.useTransaction.errors.document[0]},model:{value:(_vm.useTransaction.form_transaction.document),callback:function ($$v) {_vm.$set(_vm.useTransaction.form_transaction, "document", $$v)},expression:"useTransaction.form_transaction.document"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"type":"date","label":"Fecha de entrega","outlined":"","error-messages":_vm.useTransaction.errors.date_delivery && _vm.useTransaction.errors.date_delivery[0]},model:{value:(_vm.useTransaction.form_transaction.date),callback:function ($$v) {_vm.$set(_vm.useTransaction.form_transaction, "date", $$v)},expression:"useTransaction.form_transaction.date"}})],1)],1),_c('v-row',[(this.useWarehouseStore.transactionType === 'entry')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"label":"Tipo de pago","items":[
          { id: 'Credit', name: 'Crédito' },
          { id: 'Cash', name: 'Efectivo' },
        ],"item-value":"id","item-text":"name","outlined":"","error-messages":_vm.useTransaction.errors.payment_type && _vm.useTransaction.errors.payment_type[0]},model:{value:(_vm.useTransaction.form_transaction.payment_type),callback:function ($$v) {_vm.$set(_vm.useTransaction.form_transaction, "payment_type", $$v)},expression:"useTransaction.form_transaction.payment_type"}})],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":"Referencia","outlined":""},model:{value:(_vm.useTransaction.form_transaction.reference),callback:function ($$v) {_vm.$set(_vm.useTransaction.form_transaction, "reference", $$v)},expression:"useTransaction.form_transaction.reference"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"label":"Proveedor","outlined":"","item-value":"id","items":_vm.useTransaction.providers,"error-messages":_vm.useTransaction.form_transaction.providerId &&
          _vm.useTransaction.form_transaction.providerId[0],"filter":_vm.customFilter},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ")]}},{key:"item",fn:function({ item }){return [_c('v-avatar',{staticStyle:{"color":"white"},attrs:{"color":"orange","size":"40"}},[_vm._v(_vm._s(item.name[0]))]),_c('div',{staticClass:"fullName d-flex flex-column ml-2"},[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.address))])])])]}}]),model:{value:(_vm.useTransaction.form_transaction.providerId),callback:function ($$v) {_vm.$set(_vm.useTransaction.form_transaction, "providerId", $$v)},expression:"useTransaction.form_transaction.providerId"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"rows":"2","label":"Nota","outlined":""},model:{value:(_vm.useTransaction.form_transaction.note),callback:function ($$v) {_vm.$set(_vm.useTransaction.form_transaction, "note", $$v)},expression:"useTransaction.form_transaction.note"}})],1)],1),_c('dialog-confirm',{ref:"confirm"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }