<template>
  <v-container fluid class="p-5">
    <div elevation="0" class="d-flex justify-space-between align-centerP my-2">
      <div>
        <v-btn icon dark @click="handleBack()">
          <v-icon color="grey">mdi-chevron-left</v-icon></v-btn
        >
        <span>
          {{
            !useOrderPurchase.form_purchase_order.showed
              ? 'Nueva orden de compra'
              : '#' + useOrderPurchase.form_purchase_order.id
          }}</span
        >
      </div>
      <v-btn
        v-if="!useOrderPurchase.form_purchase_order.showed"
        :loading="loader"
        :disabled="loader"
        @click="handleStoreOrderPurchase()"
        elevation="0"
        small
        dark
        color="orange"
        dense
        >Guardar</v-btn
      >
    </div>
    <br />
    <v-row>
      <v-col cols="6" class="py-0">
        <v-autocomplete
          v-model="useOrderPurchase.form_purchase_order.provider_id"
          label="Proveedor"
          outlined
          item-value="id"
          :items="useOrderPurchase.providers"
          :error-messages="
            useOrderPurchase.errors.provider_id && useOrderPurchase.errors.provider_id[0]
          "
        >
          <template slot="selection" slot-scope="data">
            {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="{ item }">
            <v-avatar color="orange" size="40" style="color: white">{{ item.name[0] }}</v-avatar>
            <div class="fullName d-flex flex-column ml-2">
              <div>{{ item.name }}</div>
              <div>
                <span class="text-caption">{{ item.address }}</span>
              </div>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="3" class="py-0">
        <v-text-field
          label="Documento"
          v-model="useOrderPurchase.form_purchase_order.document"
          outlined
          :error-messages="useOrderPurchase.errors.document && useOrderPurchase.errors.document[0]"
        />
      </v-col>
      <v-col cols="3" class="py-0">
        <v-text-field
          :min="nowDate"
          type="date"
          label="Fecha de entrega"
          v-model="useOrderPurchase.form_purchase_order.deliveryAt"
          outlined
          :error-messages="
            useOrderPurchase.errors.deliveryAt && useOrderPurchase.errors.deliveryAt[0]
          "
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="py-0">
        <v-text-field
          label="Referencia"
          v-model="useOrderPurchase.form_purchase_order.reference"
          outlined
          :error-messages="
            useOrderPurchase.errors.reference && useOrderPurchase.errors.reference[0]
          "
        />
      </v-col>
      <v-col cols="6" class="py-0">
        <v-select
          label="Prioridad"
          item-value="value"
          item-text="text"
          :items="useOrderPurchase.priorityOptions"
          v-model="useOrderPurchase.form_purchase_order.priority"
          outlined
          :error-messages="useOrderPurchase.errors.priority && useOrderPurchase.errors.priority[0]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-textarea
          rows="2"
          label="Nota"
          v-model="useOrderPurchase.form_purchase_order.note"
          outlined
          :error-messages="useOrderPurchase.errors.note && useOrderPurchase.errors.note[0]"
        />
      </v-col>
    </v-row>
    <dialog-confirm ref="confirm"></dialog-confirm>
  </v-container>
</template>

<script>
import DialogConfirm from '@/components/DialogConfirm.vue';
import { useAppStore } from '@/store/app';
import { useWarehouseStore } from '../store/warehouse';
import { useOrderPurchase } from '../store/orderPurchase';

export default {
  components: { DialogConfirm },
  data() {
    return {
      nowDate: new Date().toISOString().slice(0, 10),
      useWarehouseStore: useWarehouseStore(),
      useOrderPurchase: useOrderPurchase(),
      useAppStore: useAppStore(),
      loader: false,
    };
  },
  created() {
    this.useOrderPurchase.fetchProviders();
    this.useOrderPurchase.form_purchase_order.warehouse_id = this.$route.params.id;
  },
  methods: {
    formHasFieldsEmpty() {
      return (
        (this.useOrderPurchase.form_purchase_order.provider_id === ''
        || this.useOrderPurchase.form_purchase_order.provider_id === null)
        && !this.useOrderPurchase.form_purchase_order.supplies.length >= 1
        && this.useOrderPurchase.form_purchase_order.document === ''
        && this.useOrderPurchase.form_purchase_order.deliveryAt === ''
        && this.useOrderPurchase.form_purchase_order.reference === ''
        && this.useOrderPurchase.form_purchase_order.priority === ''
        && this.useOrderPurchase.form_purchase_order.note === ''
      );
    },
    async handleBack() {
      if (this.formHasFieldsEmpty()
        || this.useOrderPurchase.form_purchase_order.showed) {
        this.useAppStore.toogleGeneralDialog(false, 'form-purchase-order');
        this.useOrderPurchase.resetFormRequestSupply();
      } else if (
        await this.$refs.confirm.open(
          '¿Está seguro que desea salir del formulario sin guardar los cambios?',
          'Si sale del formulario ahora, perderá cualquier cambio que haya realizado. Para continuar escribiendo en el formulario, presione el botón SEGUIR EDITANDO. De lo contrario, presione el botón SALIR para salir del formulario',
          'SEGUIR EDITANDO',
          'SALIR',
          500,
        )
      ) {
        this.useAppStore.toogleGeneralDialog(false, 'form-purchase-order');
        this.useOrderPurchase.resetFormRequestSupply();
        const { total, subtotal, tax } = this.useOrderPurchase.calculateTotals();
        this.useOrderPurchase.form_purchase_order.total = total;
        this.useOrderPurchase.form_purchase_order.subtotal = subtotal;
        this.useOrderPurchase.form_purchase_order.tax = tax;
      }
    },
    async handleStoreOrderPurchase() {
      this.loader = true;
      try {
        await this.useOrderPurchase.storeOrderPurchase(this.useOrderPurchase.form_purchase_order);
        this.useAppStore.toogleGeneralDialog(false, 'form-purchase-order');
        this.useAppStore.openSnackbar('Orden de compra creada correctamente  ✅');
        this.useOrderPurchase.fetchOrderPurchase(this.$route.params.id);
        this.useOrderPurchase.resetFormRequestSupply();
        const { total, subtotal, tax } = this.useOrderPurchase.calculateTotals();
        this.useOrderPurchase.form_purchase_order.total = total;
        this.useOrderPurchase.form_purchase_order.subtotal = subtotal;
        this.useOrderPurchase.form_purchase_order.tax = tax;
      } catch (error) {
        this.useOrderPurchase.errors = error.response.data.errors;
        this.useAppStore.openSnackbar('Error al crear la orden de compra  ❌');
      }

      this.loader = false;
    },
  },
};
</script>
